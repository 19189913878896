import React from 'react';
import styled from 'styled-components';
import { screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const VectorBase = styled.svg`
  width: ${toVW(69, 'mobile')};
  height: ${toVW(74, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(154, 'desktop')};
    height: ${toVW(166, 'desktop')};
  }
`;

export const RagnarStamp = () => (
  <VectorBase xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 165 176">
    <path
      fill="var(--illustration-accent-1)"
      d="M76.166 5.272c6.717-.078 13.473.481 20.468.623 10.044.205 20.046.156 28.83 5.708 11.259 7.118 8.313 15.194 21.336 32.336 3.581 6.05 6.297 12.481 8.184 19.268 1.191 4.29 1.836 8.675 2.602 13.045.48 2.738.965 5.48 1.43 8.223 1.155 6.797 1.935 13.628 1.4 20.535-.467 6.038-2.576 11.415-6.229 16.322-4.544 6.101-9.901 11.375-15.541 16.418-3.379 3.019-6.218 6.531-9.145 9.963-4.877 5.715-9.549 11.625-15.299 16.539-2.754 2.355-5.793 4.141-9.382 4.947-3.556.801-7.037.471-10.512-.436-4.438-1.156-8.617-3.074-13-4.384-12.64-3.777-25.853-4.459-38.866-6.139-8.321-1.074-14.688-5.102-18.807-12.483-1.943-3.484-3.307-7.196-4.501-10.987-1.957-6.19-3.502-12.508-5.982-18.532-1.011-2.457-2.258-4.806-3.173-7.302a97.592 97.592 0 01-3.318-11.03c-1.13-4.727-1.916-9.495-2.118-14.354A75.189 75.189 0 015.559 67.68c1.906-11.174 6.119-21.409 12.61-30.705 6.032-8.655 13.571-15.756 22.576-21.324A74.894 74.894 0 0157.16 8.054c6.41-2.074 12.693-2.71 19.006-2.782zM28.712 7.687c2.83-.387 5.496 2.082 5.66 5.02.16 2.874-2.103 5.407-4.962 5.562-3.076.169-5.596-2.057-5.739-5.071-.141-3.008 1.991-5.34 5.041-5.511zM18.304 19.233c1.87-.118 3.301 1.161 3.425 3.066.115 1.763-1.175 3.223-2.947 3.343-1.856.124-3.352-1.185-3.473-3.038-.12-1.849 1.128-3.25 2.995-3.371z"
    />
    <path
      fill="var(--illustration-light)"
      d="M137.134 79.246c-.011 0-.019 0-.03.002a.753.753 0 01-.77-.737c-.154-7.419-1.676-14.465-4.523-20.94-3.089-7.026-7.525-13.11-13.19-18.085-9.05-7.946-19.94-12.655-32.37-14.001a54.687 54.687 0 00-17.15.868c-4.91 1.03-9.454 2.655-13.508 4.834a.758.758 0 01-1.024-.307.748.748 0 01.308-1.016c4.178-2.247 8.86-3.921 13.915-4.983a56.234 56.234 0 0117.625-.894c12.745 1.38 23.918 6.215 33.204 14.369 5.833 5.118 10.397 11.38 13.574 18.607 2.926 6.655 4.489 13.894 4.648 21.513a.756.756 0 01-.709.77zM83.116 139.825a55.774 55.774 0 01-9.391-.218c-12.746-1.38-23.919-6.214-33.205-14.368-5.832-5.119-10.397-11.381-13.573-18.608-2.926-6.655-4.49-13.894-4.648-21.512a.747.747 0 01.739-.768c.415-.01.76.319.77.736.154 7.419 1.676 14.465 4.523 20.94 3.088 7.026 7.525 13.111 13.19 18.085 9.05 7.946 19.94 12.655 32.37 14.002 5.69.617 11.46.325 17.15-.869 4.91-1.03 9.454-2.655 13.508-4.834a.753.753 0 11.716 1.327c-4.178 2.247-8.86 3.921-13.915 4.983a58.138 58.138 0 01-8.234 1.104z"
    />
    <path
      fill="var(--illustration-light)"
      d="M102.967 165.144c-3.305.201-6.322-1.238-9.77-2.884l-.793-.377c-7.494-3.553-26.65-7.742-44.526-8.316-17.987-.575-19.89-9.047-19.961-9.408a.752.752 0 01.592-.883c.403-.081.8.181.886.582.02.089 1.87 7.672 18.534 8.203 14.383.46 35.195 3.75 45.128 8.461l.798.38c7.286 3.479 12.103 5.776 22.633-7.377 11.203-13.997 26.536-26.95 26.687-27.076a.754.754 0 011.063.09.748.748 0 01-.09 1.059c-.151.13-15.376 12.99-26.481 26.867-6.204 7.739-10.665 10.432-14.7 10.679z"
    />
    <path
      fill="var(--illustration-light)"
      d="M84.496 147.204c-35.92 2.19-66.92-25.148-69.102-60.941C13.21 50.47 40.659 19.568 76.579 17.377c35.92-2.19 66.919 25.148 69.102 60.941 2.186 35.793-25.265 66.695-61.185 68.886zM76.67 18.874c-35.092 2.14-61.903 32.329-59.77 67.293 2.131 34.965 32.416 61.672 67.504 59.532 35.088-2.139 61.903-32.329 59.771-67.293-2.132-34.964-32.413-61.672-67.505-59.532zM151.873 117.707a.759.759 0 01-.556-.196.747.747 0 01-.042-1.063c.056-.06 5.577-6.123 5.617-13.098a.746.746 0 01.759-.747.75.75 0 01.749.756c-.045 7.567-5.772 13.843-6.017 14.11a.785.785 0 01-.51.238zM44.8 21.197a.752.752 0 01-.296-1.457c.193-.069.682-.34 1.245-.656 3.818-2.134 13.962-7.802 31.682-8.25a.755.755 0 01.773.732.755.755 0 01-.735.772c-17.346.44-27.252 5.973-30.983 8.056-.727.406-1.165.651-1.48.76a.677.677 0 01-.205.043z"
    />
    <path
      fill="var(--illustration-light)"
      d="M115.258 108.456l-1.513-24.817 13.21 11.65-11.697 13.167zm.214-21.285l1.071 17.574 8.281-9.323-9.352-8.251z"
    />
    <path
      fill="var(--illustration-light)"
      d="M127.207 116.956a.755.755 0 01-.545-.189l-11.281-9.948a.752.752 0 01-.064-1.062.757.757 0 011.065-.065l11.281 9.949a.752.752 0 01-.456 1.315z"
    />
    <path
      fill="var(--illustration-light)"
      d="M116.572 117.604a.756.756 0 01-.799-.704l-1.917-31.45a.754.754 0 011.505-.091l1.918 31.449a.755.755 0 01-.707.796z"
    />
    <path
      fill="var(--illustration-light)"
      d="M107.621 106.772a.754.754 0 01-.609-1.25l7.666-8.63a.757.757 0 011.065-.066.75.75 0 01.065 1.062l-7.665 8.63a.766.766 0 01-.522.254zM105.786 102.136a.747.747 0 01-.544-.189.75.75 0 01-.065-1.061l9.122-10.27a.757.757 0 011.065-.066.75.75 0 01.065 1.062l-9.122 10.27a.768.768 0 01-.521.254zM84.517 113.791L34.966 77.275l16.86-20.301 46.447-7.46 21.76 14.92-35.516 49.357zM37.141 77.007l47.043 34.666 33.725-46.876-19.993-13.71-45.29 7.276L37.14 77.007z"
    />
    <path
      fill="var(--illustration-light)"
      d="M54.757 93.686l15.688-21.194-18.68-14.227a.745.745 0 01-.14-1.053.755.755 0 011.058-.14l19.86 15.126L58.15 91.641l46.256-7.184a.757.757 0 01.861.625.756.756 0 01-.627.86l-49.883 7.744z"
    />
    <path
      fill="var(--illustration-light)"
      d="M29.405 75.62a.753.753 0 01-.676-1.165c1.024-1.55 17.545-21.66 18.249-22.517l.18-.218.276-.047 50.07-8.573a.758.758 0 01.873.614c.07.41-.205.799-.616.87l-49.794 8.525c-5.983 7.285-17.2 20.996-17.978 22.173a.754.754 0 01-.584.337zM83.362 106.55a.752.752 0 01-.665-1.18l28.167-40.724a.757.757 0 011.05-.192.75.75 0 01.192 1.047l-28.167 40.724a.746.746 0 01-.577.325zM65.55 87.267l14.903-15.103-10.8-7.256a.748.748 0 01-.203-1.042.757.757 0 011.046-.203l12.303 8.263-12.92 13.089 11.598-1.935a.758.758 0 01.869.617.752.752 0 01-.62.867L65.55 87.267zM85.142 123.851L45.678 92.338a.752.752 0 01-.117-1.059.759.759 0 011.062-.117l38.173 30.481 6.306-9.537a.752.752 0 111.26.826l-7.22 10.919zM129.797 90.838a.744.744 0 01-.481-.137l-12.861-9.027a.75.75 0 01-.181-1.047.757.757 0 011.051-.18l12.861 9.027a.75.75 0 01-.389 1.364z"
    />
  </VectorBase>
);
