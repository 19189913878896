import PropTypes from 'prop-types';
import React from 'react';
import sprite from '@images/sprite.svg';
import VectorBase from './Vector.styled';

/**
 * @component Vector - allows to display SVG's from collection easily
 * @param {string} name
 * @param {object} style
 * @param {string} className
 *
 * Example:
 * <Vector name={`logo-${name}`} style={{ maxWidth: someVar, maxHeight: someVar }} />
 */

export const Vector = ({ name, className, style }) => (
  <VectorBase className={className} style={style}>
    <use xlinkHref={`${sprite}#${name}`} />
  </VectorBase>
);

Vector.propTypes = {
  name: PropTypes.string.isRequired,
  /* eslint-disable react/require-default-props  */
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  /* eslint-enable react/require-default-props  */
};
