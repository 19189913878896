import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from './Tag';
import { TagGroupContainer } from './TagGroup.styled';

export const TagGroup = ({ className, tags, size }) => {
  const sortedTags = tags.sort((a, b) => a.localeCompare(b));

  return (
    <TagGroupContainer className={className} size={size}>
      {sortedTags.map((tag, index) => (
        <Tag key={index} label={tag} size={size} />
      ))}
    </TagGroupContainer>
  );
};

TagGroup.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.string,
};

TagGroup.defaultProps = {
  className: '',
  size: null,
};
