import React from 'react';
import styled from 'styled-components';
import { Vector } from '@fragments';
import { screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const StarBase = styled((props) => <Vector {...props} />)`
  width: ${toVW(17, 'desktop')};
  height: ${toVW(17, 'desktop')};

  ${screenMax('lg')} {
    width: ${toVW(12, 'mobile')};
    height: ${toVW(12, 'mobile')};
  }
`;

export const StarBg = styled(StarBase)`
  position: absolute;
`;

export const StarMask = styled(StarBase)`
  position: absolute;
  clip-path: ${(props) => `inset(0 ${100 - props.rate * 100}% 0 0)
`};
`;

export const Star = styled.div`
  position: relative;
  width: ${toVW(17, 'desktop')};
  height: ${toVW(17, 'desktop')};

  ${screenMax('lg')} {
    width: ${toVW(12, 'mobile')};
    height: ${toVW(12, 'mobile')};
  }
`;
