import styled from 'styled-components';
import { color, spaceMb, spaceDt, getTypography, getFontSet, screenMin, screenMax } from '@helpers/styles';

const RowBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RowBlockCell = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.text.secondary};
  padding: ${spaceMb(2)} ${spaceMb(2)} 0 ${spaceMb(2)};
  background-color: ${color.bg.light};
  ${screenMin('lg')} {
    padding: ${spaceDt(6)} ${spaceDt(17)} ${spaceDt(6)} ${spaceDt(21.5)};
    width: 50%;
    &:nth-child(n + 3) {
      border-top: 0;
    }
    &:nth-child(even) {
      border-left: 0;
    }
  }
  ${screenMax('lg')} {
    &:not(:first-child) {
      border-top: 0;
    }
  }
`;

const RowBlockTitle = styled.h3`
  ${getTypography('heading-3')};
  margin-bottom: ${spaceMb(2)};
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(4.5)};
  }
`;
const RowBlockContent = styled.p`
  ${getFontSet('body-3', 'desktop', 'mobile')};
  ${screenMin('lg')} {
    ${getFontSet('body-1', 'desktop', 'desktop')};
  }
`;

export { RowBlockContainer, RowBlockCell, RowBlockTitle, RowBlockContent };
