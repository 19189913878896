import React from 'react';
import styled from 'styled-components';
import { Container, Vector } from '@fragments';
import { color, spaceMb, spaceDt, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const SliderContainer = styled(Container)`
  padding-right: 0;

  ${screenMin('lg')} {
    flex-direction: row;
  }
`;

export const SliderContent = styled.div`
  max-width: ${toVW(326, 'mobile')};
  max-height: ${toVW(171, 'mobile')};
  border: 1px solid ${color.text.secondary};
  margin-bottom: ${spaceMb(1)};
  overflow: hidden;

  ${screenMin('lg')} {
    width: ${toVW(1020, 'desktop')};
    height: ${toVW(534, 'desktop')};
    margin-left: ${spaceDt(15)};
    pointer-events: none;
  }
`;

export const SliderControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${screenMax('lg')} {
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-right: ${spaceMb(1.5)};
  }
`;

export const SliderArrow = styled((props) => <Vector {...props} />)`
  width: 100%;
  height: 100%;
`;

export const SliderArrowButton = styled.button`
  width: ${toVW(48, 'desktop')};
  height: ${toVW(48, 'desktop')};
  margin: ${spaceDt(3)};
  padding: 0;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background: none;
  cursor: pointer;

  ${screenMax('lg')} {
    margin: ${spaceMb(0.5)};
    width: ${toVW(30, 'mobile')};
    height: ${toVW(30, 'mobile')};
  }
`;

export const Slide = styled.div`
  ${screenMin('lg')} {
    width: ${toVW(1020, 'desktop')};
    height: ${toVW(534, 'desktop')};
  }
`;

export const SlideImg = styled.img`
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
`;
