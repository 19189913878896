import React from 'react';
import styled from 'styled-components';
import { Container, Vector } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
  ${screenMin('lg')} {
    flex-direction: row;
  }
`;
const SectionVector = styled((props) => <Vector {...props} />)`
  width: ${toVW(40, 'mobile')};
  height: ${toVW(40, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(80, 'desktop')};
    height: ${toVW(80, 'desktop')};
  }
`;
const SectionContentWrapper = styled.div`
  ${screenMin('lg')} {
    display: ${(props) => (props.hasAside ? 'block' : 'flex')};
    margin-left: ${(props) => (props.compact ? spaceDt(2) : spaceDt(16))};
    margin-right: ${spaceDt(2)};
  }

  ${screenMax('lg')} {
    width: ${(props) => (!props.childrenProvided && !props.compact ? toVW(262, 'mobile') : '100%')};
  }
`;
const SectionName = styled.h3`
  margin: 0;
  ${getTypography('heading-3')};

  ${screenMax('lg')} {
    padding-left: ${(props) => (props.compact ? 0 : spaceMb(2))};
    margin-top: ${spaceMb(1)};
    margin-bottom: ${(props) => (props.compact ? spaceMb(2) : 0)};
  }

  ${screenMin('lg')} {
    color: ${(props) => (props.compact ? color.text.secondary : 'inherit')};
  }
`;
const SectionTitle = styled.h2`
  ${getTypography('heading-2')};

  ${screenMin('lg')} {
    width: ${toVW(495, 'desktop')};
  }
`;
const SectionContent = styled.p`
  ${getTypography('body-1')};

  ${screenMin('lg')} {
    padding-left: ${(props) => !props.hasAside && spaceDt(11)};
    margin-top: ${(props) => (!props.hasAside ? spaceDt(8) : 0)};
    padding-right: ${(props) => (!props.hasAside ? 0 : spaceDt(8))};
    width: ${toVW(495, 'desktop')};
  }
`;
const SectionContentAside = styled.div`
  display: flex;
  justify-content: flex-end;

  ${screenMax('lg')} {
    margin-top: ${spaceMb(5)};
  }
`;

const SectionWrapper = styled.div`
  display: flex;

  ${screenMax('lg')} {
    flex-direction: column;
  }
`;

export {
  SectionContainer,
  SectionVector,
  SectionContentWrapper,
  SectionName,
  SectionTitle,
  SectionContent,
  SectionContentAside,
  SectionWrapper,
};
