import React from 'react';

export const Time = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 158 158" {...props}>
    <path
      stroke="var(--illustration-dark)"
      d="M64.182 23.816c-15.8 4.234-28.268 14.697-35.468 28.04-6.677 12.373-8.824 27.223-4.898 41.875m110.281-29.55c3.926 14.653 1.779 29.503-4.897 41.876-7.2 13.343-19.668 23.806-35.469 28.04"
    />
    <path
      stroke="var(--illustration-dark)"
      d="M62.517 140.517c17.627 4.723 35.51 1.568 49.909-7.307 13.353-8.23 23.71-21.38 28.091-37.727M17.483 62.517C21.863 46.17 32.221 33.02 45.574 24.79c14.4-8.875 32.282-12.03 49.91-7.307"
    />
    <path
      stroke="var(--illustration-accent-1)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M79.921 135.504c-15.984 0-30.346-7.038-40.228-18.221m80.457-72.882c-9.883-11.182-24.244-18.22-40.229-18.22-15.984 0-30.346 7.038-40.228 18.22"
    />
    <path
      stroke="var(--illustration-dark)"
      d="M63.332 47.335l-5.357-9.808 5.876 2.396m23.396 5.12l8.015-3.763-.598-6.892-5.015 4.73 4.234 8.523m3.859 2.02l2.728-3.538m4.513-5.85l-3.621 4.693m0 0l8.326-.97m-8.326.97l-.892 1.157m0 0l7.275-.35m-4.784 8.733l7.909-6.09m1.74-1.339l-1.74 1.34m0 0l.6 6.563-4.851 4.118m1.764 3.756l3.243-1.16m8.83-3.156l-4.144 1.481m0 0l-1.45 3.586-3.236-1.91m4.686-1.676l-4.686 1.676m-.341 7.355l6.033-1.84m5.145-1.568l-2.484.758m0 0l-1.549 4.564m1.549-4.564l-2.661.81m0 0l-1.496 4.369m-3.594 4.29l3.908.008m0 0l7.818.015-5.361 4.802-2.457-4.817zm0 0l-3.923 4.913m-1.186 6.25l5.032-3.577 6.165 7.398m-12.445-.38l5.645 14.811m5.528-9.683l-15.559 2.727m-2.616 3.074l2.976 3.487m4.837 5.666l-1.454-1.702m-3.383-3.964l-.706 3.853 4.089.111m-3.383-3.964l3.383 3.964m-12.45-2.352l2.914 6.758m1.809 4.194l-1.81-4.194m0 0l-7.263-.864m0 0l-1.43-3.174m1.43 3.174l3.293 7.778m-11.293-8.936L86.3 122.77m-3.153-7.083l4.105 1.1m-9.226-5.099l-2.827 12.376m-5.517-13.463l-4.962 3.924 3.255 3.663m.348-4.339l3.033 3.275-5.332 5.303m-12.18-10.488l.481 4.891 6.341-10.286.95 4.359m-19.973-6.337l7.872-.189-2.607 6.689 9.275-7.858-5.304-.272 1.19-4.442m-14.644-1.297l5.382-1.841m5.92-2.026l-5.65 1.934m0 0l-1.583 4.994m1.582-4.994l-.269.092m0 0l-5.062-2.998m-3.499-7.012l5.683 2.508 1.81-4.933 4.344 2.641m-.906-8.78l-11.837-.216m0 0l5.558-3.108m-5.558 3.108l5.63 3.314m-4.14-13.75l2.2.914m0 0l3.088-1.795 1.017 3.5m-4.105-1.705l4.105 1.705m0 0l4.84 2.01-1.296-4.288-3.544 2.278zm7.183-4.915l-9.85-6.415 6.299.21-3.043-6.89 10.184 6.998m3.043-3.453l-2.2-2.396m4.573-4.028l-10.572-2.504 6 6.532m4.572-4.028l2.092 2.53m-2.092-2.53l-5.06-7.266.487 11.294m16.452-14.307l-1.77 4.78 4.467 2.181 2.156-5.004-4.853-1.957zm8.964-5.479l-.95 12.059 8.312-11.563-1.71 11.854-5.652-12.35z"
    />
    <circle cx="79.067" cy="78.066" r="46.135" stroke="var(--illustration-dark)" transform="rotate(15 79.067 78.066)" />
    <path
      stroke="var(--illustration-accent-1)"
      d="M99.376 66.567l-2.688 2.688c-.192.192-.772.196-.96 0-.188-.196-3.647-3.648-3.647-3.648m0 0l-1.344 1.344m1.344-1.344l3.167-3.167m-4.511 4.511l1.152 1.152m-1.152-1.152l-1.152 1.152m0 0l1.152 1.152m-1.152-1.152l-1.152 1.152m0 0l1.152 1.152m-1.152-1.152L65.781 91.907m0 0l3.551 3.552c.672.671.48 1.247.192 1.535l-2.207 2.208m-1.536-7.295l-1.248 1.248m2.784 6.047l.864.864m-.864-.864l-.864-.864m2.88.576l-1.728-1.728m-4.416-1.152l-.768-.767 2.112-2.112m0 0l1.44 1.44c.48.48.384.768.096 1.056l-1.152 1.151m26.108-38.585l-2.688 2.687c-.192.192-.196.772 0 .96.197.188 3.648 3.647 3.648 3.647m0 0l-1.344 1.344m1.344-1.344l3.167-3.167m-4.511 4.511l-1.152-1.152m1.152 1.152l-1.152 1.152m0 0l-1.152-1.152m1.152 1.152l-1.152 1.152m0 0l-1.151-1.152m1.151 1.152L65.685 91.81m0 0l-3.552-3.551c-.671-.672-1.247-.48-1.535-.192l-2.208 2.207m7.295 1.536l-1.248 1.248m-6.047-2.784l-.864-.863m.864.863l.864.864m-.576-2.88l1.728 1.728m1.152 4.416l.767.768 2.112-2.112m0 0l-1.44-1.44c-.48-.48-.767-.384-1.056-.096l-1.151 1.152m38.97-2.014l-2.688-2.688c-.192-.192-.772-.196-.96 0-.188.196-3.647 3.647-3.647 3.647m0 0l-1.344-1.344-.912-.912m2.256 2.256l3.167 3.168M66.165 65.319l-1.248-1.248-2.112-2.111m3.36 3.36l2.16-2.16m-2.16 2.16l21.644 21.644m-20.108-28.94l2.207 2.208c.288.288.48.864-.192 1.536l-1.391 1.392m0 0l-2.88-2.88m23.612 27.932l.912.912.24.24m-1.152-1.152l-.24-.24-1.008-1.008m1.248 1.248l1.392-1.392m-.24 2.544s.433-.579.816-.816c.767-.477 2.303-.192 2.303-.192m-5.519-1.392s.581-.432.816-.816c.49-.8.096-2.4.096-2.4"
    />
    <path
      stroke="var(--illustration-accent-1)"
      d="M91.505 98.914l-2.688-2.688c-.192-.192-.196-.772 0-.96.197-.187 3.648-3.647 3.648-3.647m0 0l-1.344-1.344-.912-.911m2.256 2.255l3.167 3.168M66.165 65.319l-1.248-1.247-2.112-2.112m3.36 3.36l-2.16 2.159m2.16-2.16l21.644 21.645M58.87 66.855l2.208 2.208c.288.288.864.48 1.535-.192l1.392-1.392m0 0l-2.88-2.88m27.932 23.613l.912.912.24.24m-1.152-1.152l-.24-.24-1.007-1.008m1.247 1.248l-1.392 1.391m2.544-.24s-.578.434-.816.816c-.476.767-.192 2.304-.192 2.304m-1.391-5.52s-.432.582-.816.817c-.8.49-2.4.096-2.4.096m17.741-3.569h-3.8c-.272 0-.686-.407-.68-.679.006-.272 0-5.158 0-5.158m0 0h-3.19m3.19 0h4.48m-41.673 0h-1.765m1.765 0v5.022c0 .95-.543 1.222-.95 1.222h-3.123m4.073-6.244h1.743m-6.494 0h.768m2.218 0H56.68m2.218 0v1.29m34.14-1.29h1.627m-1.629 0h-28.71m28.712 0s.092.863-.082 1.37c-.345 1.01-2.122 1.718-2.122 1.718m3.832-3.088s-.102.715 0 1.154c.206.879 1.494 1.764 1.494 1.764M56.68 78.47v1.152m5.726-1.152v2.303m0-2.303h1.92m0 0s-.047.996.208 1.552c.354.772 1.632 1.44 1.632 1.44m36.169-8.841h-3.8c-.272 0-.685.408-.68.68.006.27 0 5.157 0 5.157m0 0h-3.19m3.19 0h4.48m-41.673 0h-1.765m1.765 0v-5.022c0-.95-.543-1.222-.95-1.222H56.59m4.072 6.244h1.743m-6.494 0h.768m2.218 0H56.68m2.218 0v-1.29m34.14 1.29h1.627m-1.628 0H64.325m28.712 0s.092-.863-.082-1.37c-.345-1.009-2.122-1.717-2.122-1.717m3.832 3.087s-.102-.715 0-1.153c.206-.88 1.494-1.765 1.494-1.765m-39.48 2.918v-1.151m5.726 1.151v-2.303m0 2.303h1.92m0 0s-.047-.996.208-1.552c.354-.772 1.632-1.44 1.632-1.44m18.783 27.622v-3.801c0-.272-.407-.685-.679-.679-.271.006-5.158 0-5.158 0m0 0v-1.9m0 1.9v4.48m0-6.38h1.629m-1.629 0v-1.63m0 0h1.629m-1.629 0V93.45m0 0h1.629m-1.629 0V61.414m0 0h5.022c.95 0 1.222-.543 1.222-.95v-3.122m-6.244 4.072v-4.75m-5.813 46.389v-3.801c0-.271.407-.685.678-.679.272.006 5.159 0 5.159 0m0 0v-1.9m0 1.9v4.48m0-6.38h-1.63m1.63 0v-1.629m0 0h-1.63m1.63 0v-1.629m0 0h-1.63m1.63 0V61.38m0 0h-5.023c-.95 0-1.222-.543-1.222-.95v-3.123m6.245 4.073v-4.751"
    />
    <circle
      cx="79.005"
      cy="58.69"
      r="1.672"
      stroke="var(--illustration-accent-1)"
      transform="rotate(45 79.005 58.69)"
    />
    <circle cx="79.066" cy="78.066" r="33.18" stroke="var(--illustration-dark)" transform="rotate(15 79.066 78.066)" />
  </svg>
);
