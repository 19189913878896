// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advisory-js": () => import("./../../../src/pages/advisory.js" /* webpackChunkName: "component---src-pages-advisory-js" */),
  "component---src-pages-founding-impact-js": () => import("./../../../src/pages/founding-impact.js" /* webpackChunkName: "component---src-pages-founding-impact-js" */),
  "component---src-pages-fund-js": () => import("./../../../src/pages/fund.js" /* webpackChunkName: "component---src-pages-fund-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-transparency-js": () => import("./../../../src/pages/transparency.js" /* webpackChunkName: "component---src-pages-transparency-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-case-study-list-js": () => import("./../../../src/templates/case-study-list.js" /* webpackChunkName: "component---src-templates-case-study-list-js" */),
  "component---src-templates-interview-js": () => import("./../../../src/templates/interview.js" /* webpackChunkName: "component---src-templates-interview-js" */),
  "component---src-templates-job-offer-js": () => import("./../../../src/templates/job-offer.js" /* webpackChunkName: "component---src-templates-job-offer-js" */)
}

