import React from 'react';
import styled from 'styled-components';
import { screenMin } from '@helpers/styles';

interface ContainerProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: var(--content-padding-left);
  padding-right: var(--content-padding-right);

  ${screenMin('lg')} {
    padding-left: var(--content-padding-lg-left);
    padding-right: var(--content-padding-lg-right);
  }
`;

export const Container = React.forwardRef<HTMLInputElement, ContainerProps>(
  ({ id, children, className, style, ...props }, ref) => (
    <Wrapper id={id} className={className} ref={ref} style={style} {...props}>
      {children}
    </Wrapper>
  )
);

Container.displayName = 'forwardRef(Container)';
