import React from 'react';
import PropTypes from 'prop-types';
import { Star, StarBg, StarMask } from './Rating.styled';

export const Rating = ({ rating }) =>
  [...Array(5)].map((item, index) => (
    <Star key={index}>
      <StarBg name="icon-star--empty" />
      <StarMask name="icon-star--full" rate={parseFloat(rating) - index} />
    </Star>
  ));

Rating.propTypes = {
  rating: PropTypes.string.isRequired,
};
