import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const ServiceYoutube = ({ config, className }) => (
  <SVGInterface width={236.777} height={236.777} config={config} className={className}>
    <path
      fill="#FF1D25"
      d="M205.664,39.868c36.805,11.184,31.443,45.56,29.976,80.073c-0.779,18.334,1.971,46.889-6.05,63.939
        c-5.133,10.863-15.446,11.275-26.034,11.734c-52.022,2.429-104.916,4.584-156.892,2.2c-29.426-1.375-49.272-10.221-45.651-39.051
        c-2.338-27.776-0.275-57.156,2.613-84.427c1.513-14.209,4.583-28.463,19.801-33.138c10.725-3.346,24.476,0.092,35.659-0.367
        c14.438-0.55,28.738-2.567,43.222-2.75c33.276-0.458,66.552,0.229,99.828,0.413C203.693,38.493,204.793,39.089,205.664,39.868z
            M155.063,111.966c-12.925-7.104-25.942-14.071-39.143-20.626c-8.434-4.217-16.913-8.388-25.53-12.329
        c-0.367-0.183-1.742-0.642-3.254-1.192c3.713,24.796,0.458,52.114-0.596,77.506C110.053,141.85,132.695,127.183,155.063,111.966z"
    />
  </SVGInterface>
);

ServiceYoutube.propTypes = DEFAULT_TYPES;
ServiceYoutube.defaultProps = DEFAULT_PROPS;
