import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'infinite-react-carousel';
import Img from 'gatsby-image';
import {
  SliderContainer,
  SliderContent,
  SliderControls,
  SliderArrow,
  SliderArrowButton,
  Slide,
} from './SlideShow.styled';

export const SlideShow = ({ className, slides }) => {
  const sliderRef = useRef();
  const slidePrev = () => sliderRef.current.slickPrev();
  const slideNext = () => sliderRef.current.slickNext();

  return (
    <SliderContainer className={className}>
      <SliderContent>
        <Slider ref={sliderRef} arrows={false}>
          {slides.map(({ id, alt, fluid }) => (
            <Slide key={id}>
              <Img key={id} fluid={fluid} alt={alt} />
            </Slide>
          ))}
        </Slider>
      </SliderContent>
      <SliderControls>
        <SliderArrowButton onClick={slideNext}>
          <SliderArrow name="icon-arrow-round--right" />
        </SliderArrowButton>
        <SliderArrowButton onClick={slidePrev}>
          <SliderArrow name="icon-arrow-round--left" />
        </SliderArrowButton>
      </SliderControls>
    </SliderContainer>
  );
};

SlideShow.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ).isRequired,
};
