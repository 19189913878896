import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useLockBodyScroll } from '@helpers/hooks';
import { isKpiAvailable } from '@helpers/methods';

import { Vector } from '@fragments';
import { RagnarsonRuby } from '@logos';
import {
  DrawerContainer,
  CloseButton,
  DrawerHeader,
  LinkIconRuby,
  MainContent,
  HeaderButtonContact,
  LinkItem,
  LinkContent,
  DrawerLinkExternal,
} from './Drawer.styled';

const data = [
  {
    to: '/software-development',
    title: 'Software development',
    label: 'Software development',
    external: false,
  },
  {
    to: '/advisory',
    title: 'Advisory',
    label: 'Advisory',
    external: false,
  },
  {
    to: '/case-studies',
    title: 'Case Studies',
    label: 'Case Studies',
    external: false,
  },
  {
    to: '/manifesto',
    title: 'Manifesto',
    label: 'Manifesto',
    external: false,
  },
  {
    to: '/fund',
    title: 'Fund',
    label: 'Fund',
    external: false,
  },
];
// FIXME: Remove on ENV's removal
if (isKpiAvailable()) {
  data.push({
    to: '/transparency',
    title: 'Transparency',
    label: 'Transparency',
    external: false,
  });
}

// FIXME: Remove on ENV's removal
data.push(
  {
    to: '/team',
    title: 'Team',
    label: 'Team',
    external: false,
  },
  {
    to: 'https://jobs.ragnarson.com/',
    title: 'Jobs',
    label: 'Jobs',
    external: true,
  },
  {
    to: 'https://blog.ragnarson.com/',
    title: 'Blog',
    label: 'Blog',
    external: true,
  },
  {
    to: '/founding-impact',
    title: 'Founding impact',
    label: 'Founding impact',
    external: false,
  }
);

export const Drawer = ({ visible, onClose }) => {
  const scrollToContactUs = () => {
    onClose();
    scrollTo('#the-contact-form');
  };

  useLockBodyScroll(visible);

  return (
    <Portal>
      <DrawerContainer visible={visible}>
        <DrawerHeader>
          <LinkIconRuby to="/" title="Go to Homepage" onClick={onClose}>
            <RagnarsonRuby
              config={{
                widthMb: 30,
                width: 44,
              }}
            />
          </LinkIconRuby>

          <HeaderButtonContact onClick={scrollToContactUs}>Contact us</HeaderButtonContact>

          <CloseButton onClick={onClose}>
            <Vector name="close" />
          </CloseButton>
        </DrawerHeader>
        <MainContent>
          {data.map(({ to, title, label, external }, index) => {
            const target = external ? '_blank' : null;

            return to.startsWith('/') ? (
              <LinkItem key={index} to={to} onClick={onClose} index={index} title={title} target={target}>
                <LinkContent className="link-content">{label}</LinkContent>
              </LinkItem>
            ) : (
              <DrawerLinkExternal key={index} to={to} onClick={onClose} index={index} title={title} target={target}>
                <LinkContent>{label}</LinkContent>
              </DrawerLinkExternal>
            );
          })}
        </MainContent>
      </DrawerContainer>
    </Portal>
  );
};

Drawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

Drawer.defaultProps = {
  visible: false,
  onClose: null,
};
