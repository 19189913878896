const color = {
  text: {
    primary: '#1E1E1E',
    secondary: '#8894AC',
    outline: '#EF405B',
    danger: '#FF0000',
    light: '#ffffff',
  },
  bg: {
    primary: '#1E1E1E',
    secondary: '#EF405B',
    light: '#FFFFFF',
    danger: '#FF0000',
    lightWithOpacity: (opacity) => `rgba(255, 255, 255, ${opacity})`,
  },
  accent: {
    1: '#F7F8FA',
    2: '#C8CDD7',
  },
};

export default color;
