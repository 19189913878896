import styled from 'styled-components';
import { color, screenMin, spaceMb, spaceDt, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { Link } from 'gatsby';

export const LinkRuby = styled(Link)`
  display: none;
  position: relative;
  fill: ${color.text.primary};

  ${screenMin('lg')} {
    display: inline-flex;
    position: fixed;
    z-index: 9;
    top: ${spaceDt(3)};
    left: ${spaceDt(4.5)};
  }
`;

export const HeaderContainer = styled(Container)`
  flex-direction: row;
  background-color: ${color.bg.light};
  align-items: center;
  padding-top: ${spaceMb(2)};
  padding-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    padding-top: ${spaceDt(4.5)};
    padding-bottom: ${spaceDt(4.5)};
  }
`;

export const HeaderMenuButton = styled.button`
  outline: none;
  position: fixed;
  z-index: 9;
  top: calc((var(--the-header-height) - ${toVW(19, 'mobile')}) / 2);
  right: ${toVW(16, 'mobile')};
  width: ${toVW(24, 'mobile')};
  height: ${toVW(19, 'mobile')};
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  ${screenMin('lg')} {
    width: ${toVW(44, 'desktop')};
    height: ${toVW(34, 'desktop')};
    top: ${spaceDt(4.5)};
    right: ${spaceDt(4.5)};
  }

  svg {
    fill: ${color.text.primary};
    width: 100%;
    height: 100%;
  }
`;

export const HeaderButtonContact = styled.button`
  display: none;
  margin-left: auto;
  margin-right: ${spaceMb(1)};
  text-decoration: none;
  color: ${color.text.primary};
  border: none;
  background: none;
  cursor: pointer;
  outline: none;

  ${screenMin('lg')} {
    display: block;
    margin-right: ${spaceDt(2)};
    align-self: flex-start;
  }

  ${getTypography('body-2')}
`;
