import React from 'react';
import { Portal } from 'react-portal';
import { useCookies } from 'react-cookie';
import { Button, Strings } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import { Content, Text, CookiesWrapper } from './CookiesConsent.styled';

const GA_COOKIE = process.env.GATSBY_COOKIE_CONSENT;

export const CookiesConsent = () => {
  const [cookies, setCookie] = useCookies([GA_COOKIE]);
  const handleClick = () => {
    setCookie(GA_COOKIE, true, { path: '/' });
  };

  const CookiesDots = () =>
    useStateScreenMobile() ? (
      <Strings name="bg-cookies-dots--mobile" height={139} top={5} left={-320} width={100} mobile />
    ) : (
      <Strings name="bg-cookies-dots" height={137} top={0} left={-1330} width={120} />
    );

  const ConsentBody = () => (
    <Portal>
      <CookiesWrapper>
        <CookiesDots />
        <Content>
          <Text>
            We use cookies for analytics and to improve our site. By continuing to browse the site you are agreeing to
            our Privacy Policy.
          </Text>
          <Button onClick={handleClick}>Ok, I agree</Button>
        </Content>
      </CookiesWrapper>
    </Portal>
  );

  return !cookies[GA_COOKIE] ? <ConsentBody /> : null;
};
