import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (enable) => {
  useLayoutEffect(() => {
    const htmlTag = document.getElementsByTagName('html')[0];

    if (enable) {
      htmlTag.classList.add('no-scroll');
    } else {
      htmlTag.classList.remove('no-scroll');
    }

    return () => {
      htmlTag.classList.remove('no-scroll');
    };
  }, [enable]);
};
