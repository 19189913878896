import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';
import { isDevelopment } from '@helpers/methods';
import { SectionContainer, SectionColumn, SectionContentReversed } from './Section.styled';
import { SectionContent } from './SectionContent';

export const Section = ({ left, right, template, className }) => {
  if (isDevelopment() && left && right && template)
    throw new Error('The template is not accessible. Both left & right colums have content assigned.');

  return (
    <SectionContainer className={className}>
      <SectionColumn>{left || <SectionContentReversed content={template} />}</SectionColumn>
      <SectionColumn>{right || <SectionContent content={template} />}</SectionColumn>
    </SectionContainer>
  );
};

const TYPE_COLUMN_TEMPLATE = PropTypes.shape({
  headline: PropTypes.string,
  teaser: PropTypes.string,
  cta: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }),
});

Section.propTypes = {
  right: TYPE_COMPONENT,
  left: TYPE_COMPONENT,
  template: TYPE_COLUMN_TEMPLATE,

  /* eslint-disable react/require-default-props  */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props  */
};

Section.defaultProps = {
  right: null,
  left: null,
  template: null,
};
