import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { useKeenSlider } from 'keen-slider/react';
import { TYPE_COMPONENT } from '@helpers/types';
import { spaceMb, spaceDt, screenMin, breakpoints } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { useStateScreenMobile } from '@helpers/hooks';

import { ButtonChevron } from '@fragments';

const Wrapper = styled.div`
  position: relative;
  padding-left: ${spaceMb(2)};
`;

const TopButtonChevron = styled((props) => <ButtonChevron {...props} />)`
  margin-bottom: ${spaceMb(4)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(4)};
  }
`;

const Slider = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &__slide {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
  }

  &[data-keen-slider-v] {
    flex-wrap: wrap;

    .keen-slider__slide {
      width: 100%;
    }
  }

  &[data-keen-slider-moves] * {
    pointer-events: none;
  }
`;

const SliderControls = styled.div`
  z-index: 9;
  position: absolute;
  right: ${spaceMb(2)};
  top: 50%;
  transform: translateY(-50%);
  width: ${toVW(53, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(53, 'desktop')};
    right: ${spaceDt(10)};
  }
`;

export const SliderOffcanvas = ({ slides, showControls }) => {
  const isMobileScreen = useStateScreenMobile();
  const [grabbing, setGrabbing] = useState(false);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: isMobileScreen ? 1.22 : 1.06,
    breakpoints: {
      [`(min-width: ${breakpoints.lg})`]: {
        slidesPerView: 1.06,
      },
    },
    mode: 'free',
    dragSpeed: 1,
    duration: 600,
    spacing: 8,
    dragStart: () => {
      setGrabbing(true);
    },
    dragEnd: () => {
      setGrabbing(false);
    },
  });

  const showNextSlide = (event) => event.stopPropagation() || slider.next();
  const showPrevSlide = (event) => event.stopPropagation() || slider.prev();

  const SlideList = useMemo(
    () =>
      slides?.map((slide) => (
        <div className="keen-slider__slide" key={uniqid()}>
          {slide}
        </div>
      )),
    [slides]
  );

  return (
    <Wrapper>
      {showControls && slider && (
        <SliderControls>
          <TopButtonChevron direction="left" width={53} height={53} onClick={showPrevSlide} />
          <ButtonChevron direction="right" width={53} height={53} onClick={showNextSlide} />
        </SliderControls>
      )}

      <Slider ref={sliderRef} grabbing={grabbing} className="keen-slider">
        {SlideList}
      </Slider>
    </Wrapper>
  );
};

SliderOffcanvas.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      component: TYPE_COMPONENT,
    })
  ).isRequired,
  showControls: PropTypes.bool,
};

SliderOffcanvas.defaultProps = {
  showControls: true,
};
