import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const Heyjobs = ({ config, className }) => (
  <SVGInterface width={120} height={32} className={className} config={config}>
    <path
      fill="var(--illustration-dark)"
      d="M70.017 26C63.766 26 60 22.054 60 16.5S63.766 7 70.017 7C76.268 7 80 10.946 80 16.5S76.234 26 70.017 26zm0-4.205c2.56 0 4.131-1.85 4.131-5.308 0-3.458-1.571-5.308-4.131-5.308-2.56 0-4.152 1.863-4.152 5.321 0 3.458 1.558 5.308 4.152 5.308v-.013zM101 16.325C101 22.301 97.895 26 93.046 26c-2.643 0-4.565-1.423-5.285-2.385h-.073v1.815H82V0h5.705v9.036h.082c.714-.962 2.643-2.385 5.285-2.385 4.833 0 7.928 3.699 7.928 9.674zm-5.708 0c0-3.557-1.427-5.405-3.746-5.405s-3.742 1.848-3.742 5.405c0 3.558 1.427 5.406 3.742 5.406 2.316 0 3.756-1.848 3.756-5.406h-.01zM120 20.178c0 3.771-3.061 5.822-8.364 5.822-6.834 0-9.449-2.866-9.636-6.495h5.6c.187 1.779 1.46 2.652 3.805 2.652 1.681 0 2.611-.524 2.611-1.536 0-1.537-1.792-1.747-4.815-2.374-3.251-.647-6.834-1.783-6.834-5.518 0-3.235 2.988-5.729 8.363-5.729 6.126 0 8.516 2.911 8.703 6.042h-5.607c-.187-1.43-1.01-2.2-2.951-2.2-1.681 0-2.542.56-2.542 1.434 0 1.255 1.197 1.293 4.151 1.885 3.767.777 7.516 1.964 7.516 6.017zm-85.011-.151h5.722C39.715 23.624 36.825 26 31.824 26 25.706 26 22 22.09 22 16.5c0-5.448 3.633-9.5 9.498-9.5C37.808 7 41 11.471 41 17.96H27.759c0 2.515 1.906 3.843 3.955 3.843 1.98.006 2.916-.896 3.275-1.776zm-7.23-5.448h7.482c0-2.48-1.547-3.736-3.743-3.736-2.195 0-3.74 1.257-3.74 3.736zM55 2.497C55 .873 56.09 0 57.491 0S60 .88 60 2.497 58.895 5 57.491 5 55 4.15 55 2.497zm-6 13.671L46.406 7H41l5.597 16s1.286-3.292 2.403-6.832z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M54.119 7l-2.348 8.04c-.569 2.304-2.535 9.553-5.316 11.697-.677.744-1.898.875-3.384.543H43v4.355c1.071.235 2.165.358 3.262.365 3.959 0 5.718-1.31 7.332-6.063L60 7h-5.881zM5.688 0v10.185h8.625V0H20v26h-5.688V15.535H5.688V26H0V0h5.688z"
    />
  </SVGInterface>
);

Heyjobs.propTypes = DEFAULT_TYPES;
Heyjobs.defaultProps = DEFAULT_PROPS;
