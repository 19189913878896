import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { LinkExternal } from '@fragments';
import { color, screenMin, screenMax, spaceMb, spaceDt, getTypography } from '@helpers/styles';
import { isKpiAvailable, toVW } from '@helpers/methods';

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  pointer-events: ${({ visible }) => (visible ? 'initial' : 'none')};
  z-index: ${({ visible }) => (visible ? '9' : '0')};
  position: fixed;
  background: ${color.bg.light};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  transition: opacity 0.2s ease;
`;

export const CloseButton = styled.button`
  outline: none;
  width: ${toVW(26, 'mobile')};
  height: ${toVW(26, 'mobile')};
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;

  ${screenMin('lg')} {
    width: ${toVW(32, 'desktop')};
    height: ${toVW(32, 'desktop')};
  }

  svg {
    fill: ${color.text.primary};
    width: 100%;
    height: 100%;
  }
`;

export const DrawerHeader = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaceMb(2)};

  ${screenMin('lg')} {
    padding: ${spaceDt(4.5)};
  }
`;

export const LinkIconRuby = styled(Link)`
  position: fixed;

  ${screenMin('lg')} {
    top: ${spaceDt(3)};
    left: ${spaceDt(4.5)};
  }
`;

export const HeaderButtonContact = styled.button`
  margin-left: auto;
  margin-right: ${spaceMb(1)};
  text-decoration: none;
  color: ${color.text.primary};
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  ${getTypography('body-2')}

  ${screenMin('lg')} {
    display: block;
    margin-right: ${spaceDt(2)};
  }
`;

export const MainContent = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: grid;
  grid-gap: ${spaceMb(2)};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);

  padding: 0 ${spaceMb(2)} 0 ${spaceMb(2)};
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    height: calc(100% - ${spaceDt(4)});
    grid-template-columns: 180fr 210fr 315fr 225fr 180fr;
    grid-template-rows: 235fr 94fr 94fr 202fr;

    grid-gap: ${spaceDt(3.5)};
    padding: 0 ${spaceDt(13)} 0 ${spaceDt(13)};
    margin-bottom: ${spaceDt(8)};
  }
`;

const menuItem = css`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${color.bg.primary};
  border: 1px solid ${color.bg.primary};
  color: ${color.text.light};
  text-decoration: none;
  transition-property: color, background-color;
  transition-timing-function: ease;
  transition-duration: 0.2s;

  ${getTypography('heading-3')}

  &:hover,
  &:active {
    background-color: ${color.bg.light};
    color: ${color.text.primary};
  }

  ${({ index }) => {
    switch (index) {
      case 0:
        return css`
          grid-area: 1 / 1 / 2 / 8;

          ${screenMin('lg')} {
            grid-area: 1 / 1 / 2 / 3;
          }
        `;
      case 1:
        return css`
          grid-area: 3 / 1 / 4 / 6;

          ${screenMin('lg')} {
            grid-area: 2 / 2 / 6 / 3;
          }
        `;
      case 2:
        return css`
          grid-area: 2 / 7 / 3 / 13;

          ${screenMin('lg')} {
            grid-area: 1 / 4 / 3 / 5;
          }
        `;
      case 3:
        return css`
          grid-area: 2 / 1 / 3 / 7;

          ${screenMin('lg')} {
            grid-area: ${isKpiAvailable() ? css`1 / 3 / 2 / 4` : css`1 / 3 / 3 / 4`};

            .link-content {
              margin: auto 0;
              padding-bottom: 0;
            }
          }
        `;
      case 4:
        return css`
          grid-area: 1 / 8 / 2 / 13;

          ${screenMin('lg')} {
            grid-area: 2 / 1 / 6 / 2;
          }
        `;
      case 5:
        return css`
          grid-area: 3 / 6 / 4 / 13;

          ${screenMin('lg')} {
            grid-area: ${isKpiAvailable() ? css`4 / 3 / 6 / 4` : css`1 / 5 / 2 / 6`};
          }
        `;
      case 6:
        return css`
          grid-area: 4 / 1 / 5 / 5;

          ${screenMin('lg')} {
            grid-area: ${isKpiAvailable() ? css`1 / 5 / 4 / 6` : css`2 / 5 / 6 / 6`};
          }
        `;
      case 7:
        return css`
          grid-area: ${isKpiAvailable() ? css`4 / 5 / 5 / 9` : css`4 / 5 / 5 / 13`};

          ${screenMin('lg')} {
            grid-area: 3 / 4 / 6 / 5;
          }
        `;
      case 8:
        return css`
          ${isKpiAvailable()
            ? css`
                grid-area: 4 / 9 / 5 / 13;

                ${screenMin('lg')} {
                  grid-area: 4 / 5 / 6 / 6;
                }
              `
            : css`
                grid-area: 5 / 1 / 6 / 13;

                ${screenMin('lg')} {
                  grid-area: 3 / 3 / 6 / 4;
                }

                ${screenMax('lg')} {
                  padding-top: ${spaceMb(3)};
                }
              `}
        `;
      case 9:
        return css`
          grid-area: 5 / 1 / 6 / 13;

          ${screenMax('lg')} {
            padding-top: ${spaceMb(3)};
          }

          ${screenMin('lg')} {
            grid-area: ${isKpiAvailable() ? css`2 / 3 / 4 / 4` : css`2 / 3 / 3 / 4`};
          }
        `;
      default:
        return null;
    }
  }}
`;

export const LinkContent = styled.span`
  display: flex;

  padding-bottom: ${spaceMb(2)};
  padding-left: ${spaceMb(2)};
  padding-right: ${spaceMb(2)};

  ${screenMin('lg')} {
    padding-bottom: ${spaceDt(3.5)};
    padding-left: ${spaceDt(3)};
    padding-right: ${spaceDt(3)};
  }
`;

export const LinkItem = styled(Link)`
  max-width: 100%;
  overflow: hidden;

  ${menuItem};
`;

export const DrawerLinkExternal = styled(LinkExternal)`
  ${menuItem};
`;
