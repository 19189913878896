import PropTypes from 'prop-types';

export const TYPE_SVG_CONFIG = PropTypes.shape({
  width: PropTypes.number,
  widthMb: PropTypes.number,
  fill: PropTypes.string,
});
export const TYPE_COMPONENT = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.elementType,
]);
export const TYPE_LINK = PropTypes.shape({
  to: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
});
export const TYPE_IMAGE_GATSBY_WEBP = PropTypes.shape({
  id: PropTypes.string,
  alt: PropTypes.string,
  fluid: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
});
