import styled from 'styled-components';
import { getTypography, getFontSet, screenMin, screenMax, color, spaceMb, spaceDt } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container, Link } from '@fragments';

const PageHeaderContainer = styled(Container)`
  margin-top: ${spaceMb(4)};
  margin-bottom: ${spaceMb(16)};
  ${screenMin('lg')} {
    margin-top: ${spaceDt(16.5)};
    margin-bottom: ${spaceDt(27.5)};
  }
`;

const PageName = styled.h2`
  color: ${color.text.secondary};
  margin-bottom: ${spaceMb(2)};
  ${getTypography('heading-3')}
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(2.5)};
  }
`;

const PageHeadline = styled.h1`
  margin-bottom: ${spaceMb(2)};
  ${getTypography('heading-1')}
  ${screenMin('lg')} {
    max-width: ${toVW(920, 'desktop')};
    margin-bottom: ${spaceDt(5)};
  }
`;

const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageContent = styled.div`
  flex-grow: 1;
  ${screenMin('lg')} {
    margin-right: ${spaceMb(1)};
  }
`;

const PageContentText = styled.p`
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(2.5)};
  }

  ${getTypography('body-1')}
`;

const LinkCta = styled(Link)`
  justify-content: center;
`;

const PageLinkList = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  ${screenMax('lg')} {
    justify-content: flex-start;
    margin-bottom: ${spaceMb(4)};
  }
  ${screenMin('lg')} {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const PageHeaderLink = styled(Link)`
  text-decoration: underline;
  &:not(:last-child) {
    margin-right: ${spaceMb(2)};
    ${screenMin('lg')} {
      margin-right: 0;
      margin-bottom: ${spaceDt(1.5)};
    }
  }
  ${getFontSet('heading-4')}
  ${screenMin('lg')} {
    ${getFontSet('heading-3', 'desktop')}
  }
`;

export {
  PageHeaderContainer,
  PageName,
  PageHeadline,
  PageContentWrapper,
  PageContent,
  PageContentText,
  LinkCta,
  PageLinkList,
  PageHeaderLink,
};
