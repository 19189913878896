import styled from 'styled-components';

const VectorBase = styled.svg`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
`;

export default VectorBase;
