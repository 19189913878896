import React from 'react';
import { Fonts, Normalize, Default } from './StylesGlobal.styled';

const GlobalStyles = () => (
  <>
    <Fonts />
    <Normalize />
    <Default />
  </>
);

export default GlobalStyles;
