import React, { forwardRef, useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ScrollableBase, ScrollableTrack } from './Scrollable.styled';

export const Scrollable = forwardRef(({ children, className }, ref) => {
  const refTrack = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(null);

  const updateScrollWidth = () => {
    if (!window) return;
    const refTrackCurrent = refTrack.current;

    refTrackCurrent.removeAttribute('style'); // clear current outcome, it will influence any new calculation

    const missingWidthPx = parseInt(getComputedStyle(refTrackCurrent).getPropertyValue('padding-right'), 10);
    const refTrackWidth = refTrackCurrent.scrollWidth + missingWidthPx;
    const isScrollable = window.innerWidth < refTrackCurrent.scrollWidth;

    isScrollable && setScrollWidth(refTrackWidth);
  };

  useLayoutEffect(() => {
    updateScrollWidth();
    window.addEventListener('resize', updateScrollWidth);

    return () => window.removeEventListener('resize', updateScrollWidth);
  }, []);

  return (
    <ScrollableBase className={className} ref={ref}>
      <ScrollableTrack ref={refTrack} style={{ width: scrollWidth }}>
        {children}
      </ScrollableTrack>
    </ScrollableBase>
  );
});

Scrollable.displayName = 'forwardRef(Scrollable)';

Scrollable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /* eslint-disable react/require-default-props  */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props  */
};
