import React from 'react';

export const AlwaysLearning = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 153 153" {...props}>
    <path
      stroke="var(--illustration-dark)"
      d="M48.867 28.78C35.249 36.644 26.276 49.46 22.91 63.642c-3.121 13.15-1.42 27.472 5.87 40.101m95.047-54.875c7.291 12.628 8.992 26.95 5.871 40.1-3.365 14.18-12.339 26.998-25.956 34.86"
    />
    <path
      stroke="var(--illustration-dark)"
      d="M135.695 60.39c-4.561-17.018-15.831-30.446-30.202-38.201-13.327-7.192-29.32-9.504-45.103-5.275m31.828 118.78c-15.782 4.229-31.776 1.917-45.103-5.275-14.371-7.754-25.641-21.183-30.201-38.201"
    />
    <path
      stroke="var(--illustration-accent-1)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M33.504 77.112c0-13.184 5.722-25.028 14.814-33.18m59.255 66.358c9.092-8.15 14.813-19.995 14.813-33.178 0-13.184-5.721-25.028-14.813-33.18"
    />
    <path
      stroke="var(--illustration-accent-1)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M58.311 43.3c10.057-5.806 21.607-6.67 31.819-3.34m-24.59 74.314c10.213 3.328 21.762 2.465 31.82-3.341 10.056-5.807 16.578-15.377 18.802-25.885"
    />
    <path
      stroke="var(--illustration-accent-1)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M91.252 127.968c-14.993 4.017-30.21 1.109-42.255-6.764m57.381-87.719c-12.045-7.873-27.262-10.781-42.255-6.764C49.13 30.74 37.406 40.866 30.911 53.706"
    />
    <path
      stroke="var(--illustration-dark)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M90.204 101.877l-24.17-41.863s-9.924-.951-15.314.117c-5.39 1.068-12.291 5.352-12.291 5.352l3.022 5.234m48.753 31.16s-1.003-.119-2.566-.248m2.566.248l1.364-.772M41.45 70.717l-5.43 3.135 24.177 41.875.756 1.308 3.06-1.766M41.45 70.717l9.921 17.185m36.266 13.727a78.635 78.635 0 00-2.717-.176c-3.124-.147-7.038-.129-10.024.553-5.09 1.162-6.863 2.218-12.292 5.352l-6.38-11.052m31.413 5.323l-16.986 9.807m20.916-10.331L67.39 59.23s4.139-9.07 7.759-13.203c3.62-4.135 10.78-7.97 10.78-7.97l3.023 5.235m2.615 57.813s.392-.939 1.062-2.358m-3.677-55.455l21.154 36.64c-5.429 3.135-7.229 4.143-10.78 7.97-2.084 2.245-4.057 5.626-5.492 8.404-.462.896-.869 1.73-1.205 2.441m-3.677-55.455l5.428-3.134 5.47 9.472m-7.22 49.117l6.67-3.851m7.881-32.57l11.377 19.707.756 1.308-13.342 7.703"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M90.243 77.844l17.185 4.57 4.694-17.384-6.895-1.819-12.946 7.48-2.038 7.153zm18.763 7.304l-21.815-5.8 2.846-10.122 14.673-8.477 10.184 2.595-5.888 21.804z"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M90.12 77.68l6.24 1.66 1.683-6.237-6.172-1.542-1.751 6.12zm7.899 4.536l-10.805-2.872 3.252-10.366.276-.158 10.193 2.597-2.916 10.8z"
    />
    <path fill="var(--illustration-accent-1)" d="M110.498 71.314l-14.08 8.134 1.167 2.024 14.081-8.135-1.168-2.023z" />
  </svg>
);
