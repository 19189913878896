import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const ServiceSpotify = ({ config, className }) => (
  <SVGInterface width={512} height={512} config={config} className={className}>
    <path
      fill="var(--illustration-light)"
      d="M363.485,374.099c-44.707-24.704-95.544-37.754-147.04-37.754c-30.32,0-60.742,4.581-90.409,13.611
      c-6.146,2.009-10.858,18.628-8.578,26.115c1.687,5.479,6.716,9.169,12.511,9.169c1.326,0,2.651-0.2,3.942-0.593
      c26.465-8.251,54.242-12.437,82.536-12.437c46.832,0,93.278,11.925,134.32,34.482c2.05,1.11,4.099,1.633,6.431,1.633
      c4.705,0,9.164-2.646,11.361-6.738c1.741-3.189,2.172-9.322,1.088-15.284C368.563,380.337,366.144,375.548,363.485,374.099z"
    />
    <path
      fill="var(--illustration-light)"
      d="M393.716,285.328c-54.267-28.272-115.554-43.206-177.271-43.206
      c-34.331,0-68.506,4.558-101.574,13.547c-8.106,2.204-13.587,17.947-11.187,26.953c1.873,6.926,8.2,11.774,15.38,11.773
      c1.418,0,2.842-0.19,4.232-0.566c29.845-8.279,61.195-12.481,93.149-12.481c57.446,0,112.123,13.305,162.515,39.548
      c2.443,1.22,4.895,1.83,7.437,1.83c3.586,0,9.246-6.367,14.089-15.845c2.033-3.901,2.426-8.271,1.123-12.335
      C400.324,290.532,397.52,287.259,393.716,285.328z"
    />
    <path
      fill="var(--illustration-light)"
      d="M426.031,190.377c-65.457-32.654-135.965-49.207-209.586-49.207
      c-38.029,0-76.276,4.658-113.681,13.846c-9.617,2.422-16.258,14.752-13.641,25.367c2.087,8.383,9.607,14.245,18.284,14.245
      c1.541,0,3.09-0.19,4.606-0.564c34.425-8.456,69.56-12.745,104.431-12.745c67.858,0,132.752,15.254,192.881,45.338
      c2.558,1.204,5.451,1.862,8.266,1.862c7.301,0,13.925-6.606,16.99-12.741c2.249-4.556,2.605-9.713,0.994-14.506
      C433.968,196.49,430.577,192.619,426.031,190.377z"
    />
    <path
      fill="#9BD44E"
      d="M255.901,0C114.797,0,0,114.807,0,255.921C0,397.124,114.797,512,255.901,512
      C397.114,512,512,397.124,512,255.921C512,114.807,397.114,0,255.901,0z M368.558,401.588c-2.197,4.093-6.656,6.738-11.361,6.738
      c-2.332,0-4.381-0.524-6.431-1.633c-41.041-22.558-87.487-34.482-134.32-34.482c-28.294,0-56.072,4.187-82.536,12.437
      c-1.289,0.391-2.615,0.593-3.942,0.593c-5.795,0-10.824-3.69-12.511-9.169c-2.278-7.487,2.432-24.104,8.578-26.115
      c29.669-9.031,60.089-13.611,90.409-13.611c51.495,0,102.333,13.05,147.04,37.754c2.659,1.449,5.076,6.24,6.161,12.204
      C370.73,392.266,370.299,398.396,368.558,401.588z M400.485,306.879c-4.843,9.478-10.504,15.845-14.089,15.845
      c-2.542,0-4.994-0.609-7.437-1.83c-50.393-26.243-105.069-39.548-162.516-39.548c-31.954,0-63.303,4.202-93.149,12.481
      c-1.388,0.376-2.813,0.566-4.232,0.566c-7.18,0.002-13.507-4.846-15.38-11.773c-2.401-9.004,3.08-24.747,11.187-26.953
      c33.068-8.989,67.243-13.547,101.574-13.547c61.717,0,123.003,14.934,177.271,43.206c3.804,1.93,6.607,5.206,7.892,9.216
      C402.912,298.608,402.518,302.976,400.485,306.879z M434.584,215.78c-3.065,6.136-9.689,12.741-16.99,12.741
      c-2.815,0-5.706-0.66-8.266-1.862c-60.129-30.084-125.024-45.338-192.881-45.338c-34.871,0-70.006,4.289-104.431,12.745
      c-1.516,0.374-3.065,0.564-4.606,0.564c-8.677,0-16.196-5.862-18.284-14.245c-2.617-10.614,4.024-22.944,13.641-25.367
      c37.403-9.187,75.65-13.846,113.681-13.846c73.621,0,144.127,16.552,209.586,49.207c4.546,2.243,7.935,6.114,9.547,10.898
      C437.189,206.067,436.833,211.224,434.584,215.78z"
    />
  </SVGInterface>
);

ServiceSpotify.propTypes = DEFAULT_TYPES;
ServiceSpotify.defaultProps = DEFAULT_PROPS;
