import React from 'react';
import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { screenMin } from '@helpers/styles';

interface IconVectorProps {
  widthMb?: string;
  heightMb?: string;
  width?: string;
  height?: string;
}

const Container = styled.svg<IconVectorProps>`
  width: ${({ widthMb }) => toVW(widthMb ?? 36, 'mobile')};
  height: ${({ heightMb }) => toVW(heightMb ?? 31, 'mobile')};

  ${screenMin('lg')} {
    width: ${({ width }) => toVW(width ?? 75, 'desktop')};
    height: ${({ height }) => toVW(height ?? 63, 'desktop')};
  }
`;

export const IconQuote: React.FC = (props) => (
  <Container {...props} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 63" {...props}>
    <path
      d="M10.714 63L33 23.2c1.286-2.13 1.929-4.683 1.929-7.45C34.929 6.598 28.286 0 19.07 0 10.286 0 3.214 6.81 3.214 15.75c0 7.024 4.929 13.409 12 15.324L0 57.04 10.714 63zm40.072 0L73.07 23.2c1.287-2.13 1.93-4.684 1.93-7.45C75 6.598 68.357 0 59.143 0c-8.786 0-15.857 6.81-15.857 15.75 0 7.024 4.928 13.409 12 15.324L40.07 57.04 50.786 63z"
      fill="var(--illustration-accent-2)"
    />
  </Container>
);
