import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@fragments';
import { SectionHeadline, SectionTeaser, SectionTeaserContent } from './SectionContent.styled';

export const SectionContent = ({ content, className }) => (
  <div className={className}>
    <SectionHeadline>{content.headline}</SectionHeadline>
    <SectionTeaser>
      <SectionTeaserContent>{content.teaser}</SectionTeaserContent>

      {Boolean(content.cta) && (
        <Link cta="true" to={content.cta.to}>
          {content.cta.label}
        </Link>
      )}
    </SectionTeaser>
  </div>
);

SectionContent.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string,
    teaser: PropTypes.string,
    /* eslint-disable react/require-default-props  */
    cta: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
    /* eslint-enable react/require-default-props  */
  }),

  /* eslint-disable react/require-default-props  */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props  */
};

SectionContent.defaultProps = {
  content: {
    headline: '',
    teaser: '',
  },
};
