import styled from 'styled-components';
import { screenMin, spaceDt, spaceMb } from '@helpers/styles';
import { Container } from '@fragments';
import { SectionContent } from './SectionContent';

export const SectionContainer = styled(Container)`
  margin-top: ${spaceMb(6)};
  flex-direction: column;

  ${screenMin('lg')} {
    flex-direction: row;
  }

  ${screenMin('lg')} {
    margin-top: ${spaceDt(42.5)};
  }
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${screenMin('lg')} {
    min-height: 100%;
    flex: 0 0 50%;
  }
`;

export const SectionContentReversed = styled(SectionContent)`
  ${screenMin('lg')} {
    margin-left: auto;
  }
`;
