import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, LinkExternal } from '@fragments';
import { isKpiAvailable, splitArray } from '@helpers/methods';
import FooterCompany from './FooterCompany';
import {
  FooterContent,
  FooterContentLeft,
  FooterContentRight,
  FooterMenu,
  FooterMenuList,
  FooterMenuListItem,
  FooterMenuLink,
  FooterMenuSocial,
  FooterMenuSocialIcon,
  FooterLogoRuby,
  StyledClutch,
  FooterCopyright,
  FooterCopyrightNotice,
  FooterCopyrightLinks,
  FooterVectorCity,
  FooterIconCopyright,
  FooterLinkExternal,
} from './Footer.styled';

import {
  DEFAULT_LINK_ARRAY,
  DEFAULT_LINK_ARRAY_SOCIAL,
  DEFAULT_COMPANY_DETAILS,
  DEFAULT_COPYRIGHT,
} from './Footer.data';

import {
  TYPE_CONTENT_COMPANY_DETAILS_OFFICE,
  TYPE_CONTENT_COMPANY_DETAILS_IDS,
  TYPE_CONTENT_COMPANY_NAME,
  TYPE_CONTENT_LINK_ARRAY,
  TYPE_CONTENT_LINK_ARRAY_SOCIAL,
  TYPE_LINK,
} from './Footer.types';

const createList = (array) =>
  array.map(({ to, title, label, external }, index) => {
    const target = external ? '_blank' : null;
    return (
      <FooterMenuListItem key={index}>
        {to.startsWith('/') ? (
          <FooterMenuLink to={to} title={title} target={target}>
            {label}
          </FooterMenuLink>
        ) : (
          <FooterLinkExternal to={to} title={title} target={target}>
            {label}
          </FooterLinkExternal>
        )}
      </FooterMenuListItem>
    );
  });

const footerLinks = () => {
  if (isKpiAvailable())
    DEFAULT_LINK_ARRAY.push({
      to: '/transparency',
      title: 'Transparency',
      label: 'Transparency',
      external: false,
    });

  return DEFAULT_LINK_ARRAY;
};

export const Footer = ({ content: { copyright, linkArray, linkArraySocial, companyDetails } }) => {
  const maxListLength = 6;
  const linkListArray =
    linkArray.length >= maxListLength ? useMemo(() => splitArray(linkArray), [linkArray]) : [linkArray];

  return (
    <Container id="the-footer">
      <FooterContent>
        <FooterContentLeft>
          <FooterMenu>
            <FooterMenuList primary>{createList(linkListArray[0])}</FooterMenuList>

            {Boolean(linkListArray[1]) && <FooterMenuList>{createList(linkListArray[1])}</FooterMenuList>}
          </FooterMenu>

          <FooterMenuSocial>
            {linkArraySocial.map(({ to, title, vectorName }, index) => (
              <FooterMenuListItem key={index}>
                <FooterMenuLink to={to} title={title}>
                  <FooterMenuSocialIcon name={vectorName} />
                </FooterMenuLink>
              </FooterMenuListItem>
            ))}
          </FooterMenuSocial>
        </FooterContentLeft>

        <FooterContentRight>
          <FooterCompany
            name={companyDetails.name}
            officeArray={companyDetails.officeArray}
            idArray={companyDetails.idArray}
          />

          <FooterVectorCity />

          <a href="https://clutch.co/profile/ragnarson" target="_blank" rel="noreferrer">
            <StyledClutch
              config={{
                widthMb: 60,
                width: 111,
              }}
            />
          </a>
        </FooterContentRight>

        <FooterCopyright>
          <FooterLogoRuby
            config={{
              width: 28,
            }}
          />

          <FooterCopyrightLinks>
            <LinkExternal to={copyright.privacyPolicy.to} label={copyright.privacyPolicy.label}>
              Privacy Policy
            </LinkExternal>
          </FooterCopyrightLinks>

          <FooterCopyrightNotice>
            copyright <FooterIconCopyright name="icon-copyright" /> 2021 Ragnarson
          </FooterCopyrightNotice>
        </FooterCopyright>
      </FooterContent>
    </Container>
  );
};

Footer.propTypes = {
  content: PropTypes.exact({
    linkArray: TYPE_CONTENT_LINK_ARRAY,
    linkArraySocial: TYPE_CONTENT_LINK_ARRAY_SOCIAL,
    companyDetails: PropTypes.exact({
      name: TYPE_CONTENT_COMPANY_NAME,
      officeArray: TYPE_CONTENT_COMPANY_DETAILS_OFFICE,
      idArray: TYPE_CONTENT_COMPANY_DETAILS_IDS,
    }),
    copyright: PropTypes.exact({
      privacyPolicy: TYPE_LINK,
    }),
  }),
};

Footer.defaultProps = {
  content: {
    linkArray: footerLinks(),
    linkArraySocial: DEFAULT_LINK_ARRAY_SOCIAL,
    companyDetails: DEFAULT_COMPANY_DETAILS,
    copyright: DEFAULT_COPYRIGHT,
  },
};
