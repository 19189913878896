import styled from 'styled-components';
import { Container } from '@fragments';
import { spaceDt, color, screenMin, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CookiesWrapper = styled(Container)`
  background-color: ${color.bg.light};
  border: 1px solid ${color.accent[2]};
  padding: ${spaceDt(5)} ${spaceDt(22)} ${spaceDt(5)} ${spaceDt(30)};
  color: ${color.text.light};
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;

  ${screenMin('lg')} {
    padding-left: ${spaceDt(22)};
  }
`;

const Wrapper = styled.div`
  .closed {
    display: none;
  }
`;

const Text = styled.p`
  color: ${color.text.primary};
  margin-bottom: ${spaceDt(6)};
  ${getTypography('body-3')}

  ${screenMin('lg')} {
    padding-right: ${spaceDt(1.5)};
    margin-bottom: ${spaceDt(1)};
    max-width: ${toVW(810, 'desktop')};
    ${getTypography('body-2')}
  }
`;

export { Content, Text, CookiesWrapper, Wrapper };
