import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStateScreenMobile } from '@helpers/hooks';
import { TYPE_LINK, TYPE_COMPONENT } from '@helpers/types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Button } from '@fragments';

import {
  PageHeaderContainer,
  PageName,
  PageHeadline,
  PageContentWrapper,
  PageContent,
  PageContentText,
  LinkCta,
  PageLinkList,
  PageHeaderLink,
} from './PageHeader.styled';

const MAX_LENGTH_LINK_ARRAY = 2;

export const PageHeader = ({ className, name, headline, content, ctaObj, linkArray, ...props }) => {
  if (linkArray.length > MAX_LENGTH_LINK_ARRAY)
    throw new Error(`To many links in the array. Max length: ${MAX_LENGTH_LINK_ARRAY}`);

  const Cta = useCallback(() => {
    return ctaObj.to.startsWith('/') ? (
      <LinkCta cta="true" to={ctaObj.to} title={ctaObj?.title}>
        {ctaObj.label}
      </LinkCta>
    ) : (
      <Button onClick={() => scrollTo(ctaObj.to)} title={ctaObj?.title}>
        {ctaObj.label}
      </Button>
    );
  }, [ctaObj]);

  const PageHeaderLinks = () => (
    <PageLinkList>
      {Boolean(linkArray) &&
        linkArray.map((link) => (
          <PageHeaderLink key={link.to} to={link.to} title={link.title}>
            {link.label}
          </PageHeaderLink>
        ))}
    </PageLinkList>
  );

  const ComponentList = () => content.map((Component, index) => <Component key={index} />);

  const PageContentBody = () => {
    if (typeof content === 'string') return <PageContentText>{content}</PageContentText>;

    return <ComponentList />;
  };

  return (
    <PageHeaderContainer className={className} {...props}>
      {useStateScreenMobile() && <PageHeaderLinks />}

      <PageName>{name}</PageName>
      <PageHeadline className="page-headline">{headline}</PageHeadline>
      <PageContentWrapper>
        <PageContent>
          <PageContentBody />

          {!!(ctaObj?.to && ctaObj?.label) && <Cta />}
        </PageContent>

        {!useStateScreenMobile() && <PageHeaderLinks />}
      </PageContentWrapper>
    </PageHeaderContainer>
  );
};

const TYPE_CONTENT = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(TYPE_COMPONENT)]);

PageHeader.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  name: PropTypes.string.isRequired,
  headline: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
  content: TYPE_CONTENT,
  ctaObj: TYPE_LINK,
  linkArray: PropTypes.arrayOf(TYPE_LINK),
};

PageHeader.defaultProps = {
  ctaObj: {},
  linkArray: [],
  content: '',
};
