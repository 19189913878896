import React from 'react';
import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { screenMin } from '@helpers/styles';

const IconVector = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 44 24" {...props}>
    <path
      fill="var(--illustration-dark)"
      d="M0 1a1 1 0 011-1h42a1 1 0 110 2H1a1 1 0 01-1-1zm0 11a1 1 0 011-1h36a1 1 0 110 2H1a1 1 0 01-1-1zm0 11a1 1 0 011-1h42a1 1 0 110 2H1a1 1 0 01-1-1z"
    />
  </svg>
);

export const IconHamburger = styled(IconVector)`
  width: ${({ widthMb }) => toVW(widthMb ?? 44, 'mobile')};
  height: ${({ heightMb }) => toVW(heightMb ?? 24, 'mobile')};

  ${screenMin('lg')} {
    width: ${({ width }) => toVW(width ?? 44, 'desktop')};
    height: ${({ height }) => toVW(height ?? 24, 'desktop')};
  }
`;
