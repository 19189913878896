import styled from 'styled-components';
import { screenMin, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const ContentBlockContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMin('lg')} {
    max-width: ${toVW(1020, 'desktop')};
  }
`;

const ContentBlockHeadline = styled.h2`
  ${getTypography('heading-2')}
`;

const ContentBlockContent = styled.div`
  ${getTypography('body-1')}
`;
export { ContentBlockContainer, ContentBlockHeadline, ContentBlockContent };
