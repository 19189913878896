import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { screenMin } from '@helpers/styles';
import { ButtonIcon } from '@fragments';

const IconChevron = styled.svg`
  transform-origin: center;
  transform: ${({ direction }) => `rotate(${direction === 'left' ? 90 : -90}deg)`};
  width: ${toVW(24, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(24, 'desktop')};
  }

  path {
    fill: var(--illustration-light);
  }
`;

/* FIXME: Refactor to SVGInterface and put vector inside icons collection */
export const ButtonChevron = ({ direction, ...props }) => (
  <ButtonIcon {...props}>
    <IconChevron direction={direction} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 22">
      <path
        fill="var(--illustration-light)"
        fillRule="evenodd"
        d="M.293.293a1 1 0 011.414 0L21 19.586 40.293.293a1 1 0 111.414 1.414l-20 20a1 1 0 01-1.414 0l-20-20a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </IconChevron>
  </ButtonIcon>
);

ButtonChevron.defaultProps = {
  direction: 'right',
};

ButtonChevron.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
};
