import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { spaceDt, spaceMb, color, screenMin } from '@helpers/styles';

export const ButtonIcon = styled.button`
  width: ${({ width, widthMb }) => toVW(widthMb || width, 'mobile')};
  height: ${({ height, heightMb }) => toVW(heightMb || height, 'mobile')};
  padding: ${spaceMb(0.5)};

  border: 0;
  border-radius: 50%;
  outline: 0;
  background: ${({ backgroundColor }) => backgroundColor ?? color.bg.primary};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${screenMin('lg')} {
    width: ${({ width = 48 }) => toVW(width, 'desktop')};
    height: ${({ height = 48 }) => toVW(height, 'desktop')};
    padding: ${spaceDt(0.5)};
  }
`;
