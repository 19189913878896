import { Link as LinkGatsby } from 'gatsby';
import styled, { css } from 'styled-components';
import { toVW } from '@helpers/methods';
import { color, screenMin, spaceDt, spaceMb, getTypography } from '@helpers/styles';

interface Link {
  decoration?: string;
  typography?: string;
  cta?: string;
}

export const Link = styled(LinkGatsby)<Link>`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  outline: none;
  justify-content: center;
  text-decoration: ${({ decoration }) => decoration ?? `none`};

  &:hover {
    color: ${color.text.secondary};
  }

  ${({ typography }) => getTypography(typography ?? 'body-2')}

  ${(props) =>
    !!!props.cta &&
    css`
      color: ${color.text.primary};
    `}

  ${(props) =>
    !!props.cta &&
    css`
      text-align: center;
      text-decoration: none;
      padding: ${spaceMb(1)} ${spaceMb(5)};
      overflow: hidden;
      z-index: 1;
      border-radius: ${toVW(5, 'mobile')};
      border: 1px solid ${color.bg.primary};
      color: ${color.text.light};
      transition-property: color, border-color;
      background-color: ${color.bg.primary};
      transition-timing-function: ease;
      transition-duration: 0.2s;

      ${screenMin('lg')} {
        padding: ${spaceDt(1.5)} ${spaceDt(10)};
        border-radius: ${toVW(5, 'desktop')};
      }

      &::after {
        content: '';
        transition: opacity 0.2s ease;
        z-index: -1;
        background-color: ${color.bg.light};
        position: absolute;
        display: block;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover,
      &:active {
        ${screenMin('lg')} {
          color: ${color.text.primary};

          &::after {
            opacity: 1;
          }
        }
      }

      &[disabled] {
        cursor: not-allowed;
        border-color: ${color.accent[2]};
        background-color: ${color.accent[2]};

        &:hover {
          color: ${color.text.light};

          &::after {
            opacity: 0;
          }
        }
      }
    `}
`;
