import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const Submetering = ({ config, className }) => (
  <SVGInterface config={config} className={className} width={68} height={68}>
    <path
      fill="var(--illustration-dark)"
      d="M22 18c-9.7 0-20-2.8-20-8s10.3-8 20-8 20 2.8 20 8-10.3 8-20 8zm0-14C11.4 4 4 7.2 4 10s7.4 6 18 6 18-3.2 18-6-7.4-6-18-6z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M22 28c-9.7 0-20-2.8-20-8V10c0-.6.4-1 1-1s1 .4 1 1v10c0 2.8 7.4 6 18 6s18-3.2 18-6V10c0-.6.4-1 1-1s1 .4 1 1v10c0 5.2-10.3 8-20 8z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M22 18c-9.7 0-20-2.8-20-8 0-.6.4-1 1-1s1 .4 1 1c0 2.8 7.4 6 18 6s18-3.2 18-6c0-.6.4-1 1-1s1 .4 1 1c0 5.2-10.3 8-20 8z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M22 28c-9.7 0-20-2.8-20-8V10c0-.6.4-1 1-1s1 .4 1 1v10c0 2.8 7.4 6 18 6s18-3.2 18-6V10c0-.6.4-1 1-1s1 .4 1 1v10c0 5.2-10.3 8-20 8z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M22 38c-9.7 0-20-2.8-20-8V20c0-.6.4-1 1-1s1 .4 1 1v10c0 2.8 7.4 6 18 6 2.6 0 5.1-.2 7.4-.6.6-.1 1.1.3 1.1.8.1.5-.3 1.1-.8 1.1-2.4.5-5 .7-7.7.7zM41 28.7c-.6 0-1-.4-1-1V20c0-.6.4-1 1-1s1 .4 1 1v7.7c0 .5-.4 1-1 1z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M22 48c-9.7 0-20-2.8-20-8V30c0-.6.4-1 1-1s1 .4 1 1v10c0 2.8 7.4 6 18 6 1.6 0 3.3-.1 4.9-.2.6 0 1 .3 1.1.9.1.5-.3 1-.9 1.1-1.7.1-3.4.2-5.1.2z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M46 66c-11 0-20-9-20-20s9-20 20-20 20 9 20 20-9 20-20 20zm0-38c-9.9 0-18 8.1-18 18s8.1 18 18 18 18-8.1 18-18-8.1-18-18-18z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M31 47c-.1 0-.3 0-.4-.1s-.2-.1-.3-.2c-.1-.1-.2-.2-.2-.3s-.1-.3-.1-.4v-.2c0-.1 0-.1.1-.2 0-.1 0-.1.1-.2l.1-.1c.4-.4 1-.4 1.4 0 .2.2.3.4.3.7 0 .1 0 .3-.1.4s-.1.2-.2.3c-.1.1-.2.2-.3.2s-.3.1-.4.1zM31.8 41.2c-.5-.2-.8-.8-.5-1.3.2-.5.8-.8 1.3-.5.5.2.8.8.5 1.3-.2.4-.5.6-.9.6-.2 0-.3-.1-.4-.1zm27.1-.6c-.2-.5 0-1.1.5-1.3.5-.2 1.1 0 1.3.5.2.5 0 1.1-.5 1.3-.1 0-.3.1-.4.1-.3 0-.7-.2-.9-.6zm-24.2-4.5c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3zm21.2 0c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-.2.2-.5.3-.7.3-.3 0-.5-.1-.7-.3zm-16.6-3.6c-.2-.5 0-1.1.5-1.3.5-.2 1.1 0 1.3.5.2.5 0 1.1-.5 1.3-.1.1-.3.1-.4.1-.3 0-.7-.2-.9-.6zm12 .6c-.5-.2-.8-.8-.5-1.3.2-.5.8-.8 1.3-.5.5.2.8.8.5 1.3-.2.4-.5.6-.9.6-.1-.1-.2-.1-.4-.1zM46 32c-.6 0-1-.5-1-1s.4-1 1-1c.5 0 1 .5 1 1s-.5 1-1 1zM61 47c-.1 0-.3 0-.4-.1-.1 0-.2-.1-.3-.2-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.4-.4 1-.4 1.4 0 .2.2.3.5.3.7 0 .3-.1.5-.3.7-.1.1-.2.2-.3.2-.1.1-.3.1-.4.1zM46 49c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm0-4c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M44.9 45.3c-.3 0-.6-.2-.8-.4l-4.9-7.3c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l4.9 7.3c.3.5.2 1.1-.3 1.4-.2.1-.4.1-.6.1z"
    />
    <path fill="var(--illustration-dark)" d="M56 53H36c-.6 0-1-.4-1-1s.4-1 1-1h20c.6 0 1 .4 1 1s-.4 1-1 1z" />
    <path fill="var(--illustration-dark)" d="M53 58H39c-.6 0-1-.4-1-1s.4-1 1-1h14c.6 0 1 .4 1 1s-.4 1-1 1z" />
  </SVGInterface>
);

Submetering.defaultProps = DEFAULT_PROPS;
Submetering.propTypes = DEFAULT_TYPES;
