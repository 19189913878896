import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { screenMin, getTypography, spaceMb, spaceDt, color } from '@helpers/styles';

// TODO: Extend button with themes and button type if use case occurs

export const Button = styled.button`
  cursor: pointer;
  padding: ${spaceMb(1)} ${spaceMb(5)};
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: ${toVW(5, 'mobile')};
  border: 1px solid ${color.bg.primary};
  color: ${color.text.light};
  outline: none;
  transition-property: color, border-color;
  background-color: ${color.bg.primary};
  transition-timing-function: ease;
  transition-duration: 0.2s;
  ${getTypography('body-2')}

  &::after {
    content: '';
    transition: opacity 0.2s ease;
    z-index: -1;
    background-color: ${color.bg.light};
    position: absolute;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover,
  &:active {
    ${screenMin('lg')} {
      color: ${color.text.primary};

      &::after {
        opacity: 1;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: ${color.accent[2]};
    background-color: ${color.accent[2]};

    &:hover {
      color: ${color.text.light};

      &::after {
        opacity: 0;
      }
    }
  }

  ${screenMin('lg')} {
    padding: ${spaceDt(1.5)} ${spaceDt(10)};
    border-radius: ${toVW(5, 'desktop')};
  }
`;
