import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import _flow from 'lodash.flow';
import { signal } from '@helpers/methods';
import { GATSBY_COOKIE_SOURCE_TRACKING } from '@constants';

// Inspired heavily by: http://clients.first-rate.com/firstrate/NewSession%20and%20ReturningSession%20cookies.txt
const sourceCookieBakery = () => {
  const expireInDays = (days) => {
    if (typeof days !== 'number') signal('Days have the wrong type.');

    return days * 24 * 60 * 60;
  };

  const getDate = () => {
    const date = new Date();

    return date.toISOString().slice(0, 10).replace(/-/g, '');
  };

  const getTrafficObj = (source = 'direct', medium = 'none', campaign = 'direct', term, content) => {
    return {
      [getDate()]: {
        source,
        medium,
        campaign: medium === 'referral' ? '' : campaign,
        term,
        content,
        date: getDate(),
        timestamp: Date.now(),
      },
    };
  };

  const getUtm = (search) => {
    const params = new URLSearchParams(search);

    return getTrafficObj(
      params.get('utm_source'),
      params.get('utm_medium'),
      params.get('utm_campaign'),
      params.get('utm_term'),
      params.get('utm_content')
    );
  };

  const getTrafficSourceValues = () => {
    if (!document) return signal('Document object was not present.');

    const {
      referrer,
      location: { search },
    } = document;

    if (!referrer && !search) return getTrafficObj();

    if (search.includes('utm_source')) return getUtm(search);

    if (search.includes('gclid')) return getTrafficObj('google', 'cpc', 'not set');

    if (referrer) return getTrafficObj(referrer.match(/:\/\/(.[^/]+)/)[1], 'organic');

    return getTrafficObj();
  };

  const stringify = (obj) => JSON.stringify(obj);

  const getCookieProps = () => {
    return {
      path: '/',
      maxAge: expireInDays(182),
      domain: window?.location?.hostname,
    };
  };

  const addNewEntry = (obj) => {
    return { ...obj, ...getTrafficSourceValues() };
  };

  const getCookieValue = _flow([addNewEntry, stringify]);

  const getEntryDates = (obj) => (obj ? Object.keys(obj) : []);

  const shouldAddEntry = (arr) => !arr.includes(getDate());

  const shouldBake = _flow([getEntryDates, shouldAddEntry]);

  const bake = (cookies, cookieName, set) =>
    shouldBake(cookies[cookieName]) && set(cookieName, getCookieValue(cookies[cookieName]), getCookieProps());

  return {
    bake,
  };
};

export const useSourceTracking = () => {
  const [cookies, setCookie] = useCookies([GATSBY_COOKIE_SOURCE_TRACKING]);
  const bakery = sourceCookieBakery();
  const handleCookieDelivery = (name, value, options) => {
    signal(`${name} cookie is being created.`);
    setCookie(name, value, options);
  };

  useEffect(() => {
    bakery.bake(cookies, GATSBY_COOKIE_SOURCE_TRACKING, handleCookieDelivery);
  }, []);
};
