/**
 * splitContent - splits array into two smaller arrays. Array can be equal, or the first array will have +1 elements
 * if the length is an odd number.
 *
 * @param {Array.<{to: string, label: string, title: string}> array
 *
 * Example output: [[{...}, {...}, {...}], [{...}, {...}]]
 */

export const splitArray = (array) => {
  if (!Array.isArray(array)) throw new Error('The @param array is not an Array.');

  const chunkSize = Math.round(array.length / 2);
  const chunkPrimary = array.slice(0, chunkSize);
  const chunkSecondary = array.slice(chunkSize, array.length);

  return [chunkPrimary, chunkSecondary];
};
