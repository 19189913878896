import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';
import { CardCaseSlotFlags, CardCaseContainer, CardCaseHeader, CardCaseTitle, CardCaseBody } from './CardCase.styled';

export const CardCase = ({ className, to, logo, slotFlags, slotTags, title, body, size }) => (
  <CardCaseContainer className={className} to={to} title={`Read the "${title}" case study`} size={size}>
    <CardCaseHeader size={size}>
      {logo}
      <CardCaseSlotFlags>{slotFlags}</CardCaseSlotFlags>
    </CardCaseHeader>

    {slotTags}

    <CardCaseTitle size={size}>{title}</CardCaseTitle>
    <CardCaseBody>{body}</CardCaseBody>
  </CardCaseContainer>
);

CardCase.propTypes = {
  to: PropTypes.string.isRequired,
  logo: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
  slotFlags: TYPE_COMPONENT,
  slotTags: TYPE_COMPONENT,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

CardCase.defaultProps = {
  className: null,
  slotFlags: null,
  slotTags: null,
  size: '',
};
