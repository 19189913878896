import styled from 'styled-components';
import { screenMin, spaceDt, spaceMb, getTypography, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const SectionHeadline = styled.h2`
  position: relative;
  margin-top: ${spaceMb(1.5)};
  margin-bottom: ${spaceMb(2)};
  ${getTypography('heading-2')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(1.5)};
    margin-bottom: ${spaceDt(6.5)};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: ${toVW(40, 'mobile')};
    height: ${toVW(2, 'mobile')};
    background-color: ${color.accent[2]};
    top: calc(100% + ${toVW(4, 'mobile')});
    left: 0;

    ${screenMin('lg')} {
      top: calc(100% + ${toVW(20, 'desktop')});
      width: ${toVW(75, 'desktop')};
      height: ${toVW(2, 'desktop')};
    }
  }
`;

export const SectionTeaser = styled.div`
  color: ${color.text.primary};
  padding: 0 ${spaceMb(2)};
  margin-bottom: ${spaceMb(1.5)};

  ${screenMin('lg')} {
    padding: 0 ${spaceDt(5.5)};
    margin-bottom: ${spaceDt(3)};
  }
`;

export const SectionTeaserContent = styled.p`
  color: inherit;
  margin-bottom: ${spaceMb(1, 5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(3)};
  }
`;
