import PropTypes from 'prop-types';
import { TYPE_SVG_CONFIG } from '@helpers/types';

export const DEFAULT_PROPS = {
  config: {
    width: 150,
    widthMb: 150,
    fill: null,
  },
  className: null,
};

export const DEFAULT_TYPES = {
  config: TYPE_SVG_CONFIG,
  className: PropTypes.string,
};
