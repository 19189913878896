import React from 'react';

export const IconCheckOval = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 29" {...props}>
    <path
      fill="var(--illustration-dark)"
      fillRule="evenodd"
      d="M21.521 9.327a1 1 0 0 1 .175 1.403l-9.088 11.677-5.89-5.787a1 1 0 0 1 1.401-1.427l4.29 4.214 7.709-9.905a1 1 0 0 1 1.403-.175z"
    />
    <path
      fill="var(--illustration-dark)"
      fillRule="evenodd"
      d="M14.5 27C21.404 27 27 21.404 27 14.5S21.404 2 14.5 2 2 7.596 2 14.5 7.596 27 14.5 27zm0 2C22.508 29 29 22.508 29 14.5S22.508 0 14.5 0 0 6.492 0 14.5 6.492 29 14.5 29z"
    />
  </svg>
);
