import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const RagnarsonRuby = ({ className, config }) => (
  <SVGInterface width={30} height={30} config={config} className={className}>
    <path
      fill="var(--illustration-dark)"
      fillRule="evenodd"
      d="M1 10.516l.277-.249L7.78 4h15.268l6.78 6.535-14.414 14.184L1 10.534l.01-.009-.01-.01zm7.207 4.052l-4.048-3.93 4.13-3.954 4.08 3.843-4.162 4.041zm7.206-4.052l-4.614-4.388h11.543l4.562 4.472-4.284 4.216v-.006H10.99l4.423-4.294zm-5.382 6.095l5.382 5.298 5.383-5.298H10.03z"
      clipRule="evenodd"
    />
  </SVGInterface>
);

RagnarsonRuby.propTypes = DEFAULT_TYPES;
RagnarsonRuby.defaultProps = DEFAULT_PROPS;
