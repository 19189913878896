import styled from 'styled-components';
import { toVW } from '@helpers/methods';

export const StringsContainer = styled.div`
  position: absolute;
  top: ${(props) => toVW(props.top, props.mobile ? 'mobile' : 'desktop')};
  left: ${(props) => toVW(props.left, props.mobile ? 'mobile' : 'desktop')};
  width: ${(props) => `calc(100% - ${toVW(props.left, props.mobile ? 'mobile' : 'desktop')})`};
  height: ${(props) => toVW(props.height, props.mobile ? 'mobile' : 'desktop')};
  z-index: -1;
`;

export const SvgWrapper = styled.svg`
  width: 100%;
  height: 100%;
`;

export const StringsWrapper = styled.div`
  position: relative;
`;
