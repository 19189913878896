import React from 'react';
import {
  TYPE_CONTENT_COMPANY_DETAILS_OFFICE,
  TYPE_CONTENT_COMPANY_DETAILS_IDS,
  TYPE_CONTENT_COMPANY_NAME,
} from './Footer.types';
import {
  FooterCompanyWrapper,
  FooterCompanyOffice,
  FooterCompanyOfficeCity,
  FooterCompanyContent,
  FooterCompanyDetails,
} from './FooterCompany.styled';

const FooterCompany = ({ name, officeArray, idArray }) => (
  <FooterCompanyWrapper>
    {officeArray.map((office, index) => (
      <FooterCompanyOffice key={index}>
        <FooterCompanyOfficeCity>
          {office.country} <br /> {office.city}
        </FooterCompanyOfficeCity>

        {office.address.map((addressLine, indexAddress) => (
          <FooterCompanyContent key={indexAddress}>{addressLine}</FooterCompanyContent>
        ))}
      </FooterCompanyOffice>
    ))}

    <FooterCompanyDetails>
      <FooterCompanyContent>{name}</FooterCompanyContent>
      {idArray.map((id, index) => (
        <FooterCompanyContent key={index}>{`${id[0].toUpperCase()}: ${id[1]}`}</FooterCompanyContent>
      ))}
    </FooterCompanyDetails>
  </FooterCompanyWrapper>
);

FooterCompany.propTypes = {
  name: TYPE_CONTENT_COMPANY_NAME.isRequired,
  officeArray: TYPE_CONTENT_COMPANY_DETAILS_OFFICE.isRequired,
  idArray: TYPE_CONTENT_COMPANY_DETAILS_IDS.isRequired,
};

export default FooterCompany;
