import React from 'react';
import styled, { css } from 'styled-components';
import { spaceMb, spaceDt, color, screenMin, screenMax, getFontSet } from '@helpers/styles';
import { toVW } from '@helpers/methods';

import { CityBerlin } from '@illustrations';
import { Clutch, RagnarsonRuby } from '@logos';
import { LinkExternal, Vector } from '@fragments';

export const FooterContent = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border-top: 1px solid ${color.accent[2]};
  z-index: 1;
  padding-top: ${spaceMb(4)};
  padding-bottom: ${spaceMb(4)};

  ${screenMin('lg')} {
    flex-direction: row;
    padding-top: ${spaceDt(4)};
    padding-bottom: ${spaceDt(4)};
  }
`;

export const FooterContentLeft = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${screenMin('lg')} {
    flex-direction: column;
    width: 42%;
    padding-left: ${spaceDt(5)};
    padding-right: ${spaceDt(2)};
  }
`;

export const FooterContentRight = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${screenMin('lg')} {
    width: 58%;
    padding-left: ${spaceDt(2)};
    padding-right: ${spaceDt(5)};
  }
`;

export const FooterMenu = styled.div`
  ${screenMax('lg')} {
    flex-grow: 1;
  }

  ${screenMin('lg')} {
    display: flex;
    padding-top: ${spaceDt(5)};
  }
`;

export const FooterMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.primary &&
    css`
      ${screenMin('lg')} {
        margin-right: ${spaceDt(10)};
      }
    `}
`;

export const FooterMenuListItem = styled.li`
  margin-bottom: ${spaceMb(3)};
  position: relative;

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(3)};
  }
`;

export const FooterMenuLink = styled(LinkExternal)`
  ${getFontSet('body-3', 'desktop', 'mobile')}

  ${screenMin('lg')} {
    ${getFontSet('heading-4', 'desktop')}
  }
`;

export const FooterMenuSocialIcon = styled((props) => <Vector {...props} />)`
  width: ${toVW(30, 'mobile')};
  height: ${toVW(30, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(30, 'desktop')};
    height: ${toVW(30, 'desktop')};
  }
`;

export const FooterMenuSocial = styled(FooterMenuList)`
  margin-top: ${spaceMb(3)};
  display: inline-flex;
  fill: ${color.primary};

  ${screenMin('lg')} {
    display: flex;
    margin-top: ${spaceDt(3)};
    flex-direction: row;
    margin-left: ${toVW(-10, 'desktop')}; /* Fixed for facebook first */
  }

  li:not(:last-child) {
    ${screenMin('lg')} {
      margin-right: ${spaceDt(8.5)};
    }
  }
`;

export const FooterLogoRuby = styled(RagnarsonRuby)`
  ${screenMin('lg')} {
    margin-right: ${spaceDt(1.5)};
  }
`;
export const StyledClutch = styled(Clutch)`
  position: absolute;
  bottom: ${spaceMb(2)};
  right: ${spaceMb(1)};

  ${screenMin('lg')} {
    bottom: ${spaceDt(24)};
    right: ${spaceDt(2)};
  }
`;

export const FooterCopyright = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${spaceMb(10.5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(2)};
  }
`;

export const FooterCopyrightNotice = styled.p`
  width: 100%;
  margin-left: auto;
  color: ${color.text.primary};
  margin-bottom: ${spaceMb(4)};
  text-align: center;

  ${screenMin('lg')} {
    margin-bottom: 0;
    width: auto;
    text-align: left;
    margin-bottom: ${spaceDt(2)};
  }
`;

export const FooterCopyrightLinks = styled.p`
  color: ${color.text.secondary};
  width: 100%;
  text-align: center;

  ${screenMin('lg')} {
    width: auto;
    margin-bottom: 0;
    text-align: left;
  }
`;

export const FooterVectorCity = styled(CityBerlin)`
  width: ${toVW(370, 'mobile')};
  height: ${toVW(193, 'mobile')};
  margin-top: ${spaceMb(-8)};
  order: -1;
  z-index: -1;

  ${screenMin('lg')} {
    order: initial;
    margin-left: auto;
    margin-top: ${spaceDt(-2.5)};
    width: ${toVW(626, 'desktop')};
    height: ${toVW(327, 'desktop')};
  }
`;

export const FooterIconCopyright = styled((props) => <Vector {...props} />)`
  max-width: ${spaceMb(2)};
  max-height: ${spaceMb(2)};

  ${screenMin('lg')} {
    max-width: ${spaceDt(2)};
    max-height: ${spaceDt(2)};
  }
`;

export const FooterLinkExternal = styled(LinkExternal)`
  ${getFontSet('body-3', 'desktop', 'mobile')}

  ${screenMin('lg')} {
    ${getFontSet('heading-4', 'desktop')}
  }
`;
