import React from 'react';
import PropTypes from 'prop-types';
import { StyledTagContent } from './Tag.styled';

export const Tag = ({ label, ...props }) => {
  return <StyledTagContent {...props}>{label}</StyledTagContent>;
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
};
