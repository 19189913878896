import React from 'react';
import { Link } from '@fragments';
import styled from 'styled-components';
import { color, spaceMb, spaceDt, getTypography, screenMax, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const CardCaseSlotFlags = styled.div`
  position: absolute;
  bottom: ${spaceMb(2)};
  right: ${spaceMb(2)};

  ${screenMin('lg')} {
    bottom: ${spaceDt(2)};
    right: ${spaceDt(2)};
  }
`;

export const CardCaseHeader = styled.div`
  position: relative;
  height: ${(props) => toVW(props.size === 'small' ? 300 : 390, 'desktop')};
  border: 1px solid ${color.accent[2]};
  display: flex;
  align-items: center;
  transition-timing-function: ease;
  transition-duration: 0.2s;
  background-color: ${color.bg.light};

  ${screenMax('lg')} {
    max-width: ${toVW(170, 'mobile')};
    height: ${toVW(170, 'mobile')};
  }
`;

export const CardCaseContainer = styled((props) => <Link {...props} />)`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
  min-width: ${(props) => toVW(props.size === 'small' ? 300 : 390, 'desktop')};
  justify-content: flex-start;

  .component-logo {
    max-width: calc(100% - ${spaceMb(4)});
    height: auto;
    margin: 0 auto;

    ${screenMin('lg')} {
      max-width: calc(100% - ${spaceDt(4)});
    }
  }

  &:hover .component-logo {
    filter: invert(1);
  }

  &:hover ${CardCaseHeader} {
    background-color: ${color.bg.primary};
  }
`;

export const CardCaseTitle = styled.h3`
  margin: ${spaceDt(2)} 0;
  color: ${color.text.primary};

  ${(props) => getTypography(props.size === 'small' ? 'body-2' : 'heading-3')};

  ${screenMax('lg')} {
    margin: ${spaceMb(0.5)} 0;

    ${getTypography('heading-4')};
  }
`;

export const CardCaseBody = styled.p`
  margin: 0;
  color: ${color.text.secondary};

  ${getTypography('body-4')};
`;
