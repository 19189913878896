import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';
import { ContentBlockContainer, ContentBlockHeadline, ContentBlockContent } from './ContentBlock.styled';

export const ContentBlock = ({ className, headline, content }) => {
  return (
    <ContentBlockContainer className={className}>
      {Boolean(headline) && <ContentBlockHeadline>{headline}</ContentBlockHeadline>}
      <ContentBlockContent className="content-block__content">{content}</ContentBlockContent>
    </ContentBlockContainer>
  );
};

ContentBlock.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  headline: PropTypes.string,
  /* eslint-enable react/require-default-props */
  content: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
};
