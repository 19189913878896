import React from 'react';
import * as logos from '@logos';
import { signal } from './signal';

/* eslint-disable react/prop-types */
export const getLogo = (name) => {
  const logoArr = Object.entries(logos).find(([logoName]) => logoName.toLowerCase() === name);

  if (typeof name !== 'string') return signal('The "name" parameter is not a string!');
  if (!logoArr || logoArr.length !== 2)
    throw new Error(`The following logo: "${name}" does not exist or the array contents does not meet requirements.`);

  return ({ config, className }) => {
    const Component = logoArr[1];

    return Boolean(Component) && <Component config={config} className={`${className} component-logo`} />;
  };
};
/* eslint-enable react/prop-types */
