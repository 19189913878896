import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import 'simplebar/dist/simplebar.min.css';

import { Container } from '@fragments';

const ScrollableBase = styled(SimpleBar)`
  .simplebar-scrollbar::before {
    background-color: transparent;
  }
  width: 100%;
`;

const ScrollableTrack = styled(Container)`
  align-items: flex-start;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
`;

export { ScrollableBase, ScrollableTrack };
