import React from 'react';

export const IconSuccess = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 22" {...props}>
    <circle cx="10.2474" cy="10.9998" r="10" transform="rotate(-15 10.2474 10.9998)" fill="white" />
    <path
      fill="var(--illustration-accent-1)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7011 7.14281C16.0387 7.37251 16.0994 7.79798 15.8366 8.09311L9.4488 15.2665C9.08619 15.6737 8.4642 15.7154 8.05052 15.3602L4.2318 12.0811C3.92656 11.819 3.92222 11.3903 4.22209 11.1235C4.52197 10.8567 5.01251 10.8529 5.31775 11.115L8.641 13.9686L14.6138 7.26128C14.8766 6.96615 15.3634 6.91311 15.7011 7.14281Z"
    />
  </svg>
);
