import styled, { css } from 'styled-components';
import { screenMin, screenMax, spaceMb, spaceDt, color, getTypography } from '@helpers/styles';
import { Button } from '@fragments';

export const StyledTagContent = styled(Button)`
  cursor: default;
  background-color: ${color.bg.light};
  color: ${color.text.primary};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          border: 1px solid ${({ borderColor }) => borderColor ?? color.accent[2]};
          padding: 0 ${spaceMb(1)};
          margin: ${spaceMb(0.5)} ${spaceMb(0.5)} 0 0;

          ${screenMin('lg')} {
            padding: ${spaceDt(0.5)} ${spaceDt(1)};
            margin: ${spaceDt(1)};
          }

          ${screenMax('lg')} {
            ${getTypography('body-4')};
          }
        `;
      default:
        return css`
          border: 1px solid ${({ borderColor }) => borderColor ?? color.bg.primary};
          padding: ${spaceMb(0.5)} ${spaceMb(1)};
          margin: ${spaceMb(0.5)};

          ${screenMin('lg')} {
            padding: ${spaceDt(1)} ${spaceDt(2)};
            margin: ${spaceDt(1.5)};
          }
        `;
    }
  }};
`;
