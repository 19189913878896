import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Ragnarson, RagnarsonRuby } from '@logos';
import { IconHamburger } from '@icons';
import { scrollToContactForm } from '@helpers/methods';
import { HeaderContainer, HeaderButtonContact, HeaderMenuButton, LinkRuby } from './Header.styled';

export const Header = React.memo(({ onDrawerToggle }) => {
  const refHeader = useRef(null);

  const updateHeaderHeight = () => {
    const rootStyles = document.documentElement.style;
    rootStyles.setProperty('--the-header-height', `${refHeader.current.offsetHeight}px`);
  };

  useEffect(() => {
    if (typeof document === 'undefined' || typeof window === 'undefined') return null;

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => window.removeEventListener('resize', updateHeaderHeight);
  }, []);

  return (
    <HeaderContainer ref={refHeader}>
      <LinkRuby to="/" title="Go to Homepage">
        <RagnarsonRuby
          config={{
            widthMb: 30,
            width: 44,
          }}
        />
      </LinkRuby>

      <Link to="/" title="Go to Homepage">
        <Ragnarson
          config={{
            widthMb: 145,
            width: 194,
          }}
        />
      </Link>

      <HeaderButtonContact onClick={scrollToContactForm}>Contact us</HeaderButtonContact>

      <HeaderMenuButton onClick={onDrawerToggle}>
        <IconHamburger />
      </HeaderMenuButton>
    </HeaderContainer>
  );
});

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};
