import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TYPE_COMPONENT } from '@helpers/types';
import { toVW } from '@helpers/methods';
import { screenMin } from '@helpers/styles';

const SVGInterfaceBase = ({ children, width, height, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox={`0 0 ${width} ${height}`} className={className}>
    {children}
  </svg>
);

SVGInterfaceBase.propTypes = {
  children: TYPE_COMPONENT.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SVGInterfaceBase.defaultProps = {
  className: null,
};

const calcWidth = (defaultParam) => (inputSize) =>
  (Math.round(inputSize) / defaultParam) * defaultParam || defaultParam;
const calcHeight = (defaultWidth, defaultHeight) => (inputSize) =>
  (Math.round(inputSize) / defaultWidth) * defaultHeight || defaultHeight;

export const SVGInterface = styled(SVGInterfaceBase)`
  ${({ width: viewboxWidth, height: viewboxHeight }) => {
    const getWidth = calcWidth(viewboxWidth);
    const getHeight = calcHeight(viewboxWidth, viewboxHeight);

    return css`
      width: ${({ config: { widthMb, width } }) => toVW(getWidth(widthMb ?? width), 'mobile')};
      height: ${({ config: { widthMb, width } }) => toVW(getHeight(widthMb ?? width), 'mobile')};

      ${screenMin('lg')} {
        width: ${({ config: { width } }) => toVW(getWidth(width), 'desktop')};
        height: ${({ config: { width } }) => toVW(getHeight(width), 'desktop')};
      }

      ${({ config: { fill } }) => {
        if (!fill) return null;

        return css`
          * {
            fill: ${fill};
          }
        `;
      }}
    `;
  }}
`;
