/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ClientOnly } from '@fragments';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSourceTracking } from '@helpers/hooks';
import { Header, Drawer, Footer, CookiesConsent, PasswordModal } from './components';

const LayoutBody = ({ children }) => {
  const [stateDrawer, setStateDrawer] = useState(false);
  const toggleDrawer = useCallback(() => setStateDrawer((previous) => !previous), [setStateDrawer]);

  if (!process.env.GATSBY_RECAPTCHA_KEY) throw new Error('Recaptcha key was not provided!');

  useSourceTracking();

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
      scriptProps={{
        async: true,
        appendTo: 'head',
      }}
    >
      <Header onDrawerToggle={toggleDrawer} />

      {children}

      {/* TODO: Replace with skeleton loader */}
      <Footer />
      <CookiesConsent />
      <Drawer visible={stateDrawer} onClose={toggleDrawer} />
    </GoogleReCaptchaProvider>
  );
};

LayoutBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const initialPasswordValue = () => {
  try {
    return JSON.parse(localStorage.getItem('password-correct'));
  } catch {
    return false;
  }
};

const Layout = ({ children }) => {
  const isModalEnabled = process.env.GATSBY_GATEKEEPER === 'true';
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(initialPasswordValue());

  const handleChange = (newValue) => {
    setIsPasswordCorrect(newValue);
    localStorage.setItem('password-correct', JSON.stringify(newValue));
  };

  const conditionalLayoutBody = () => {
    if (!isModalEnabled || (isModalEnabled && isPasswordCorrect)) return <LayoutBody>{children}</LayoutBody>;

    return (
      <ClientOnly>
        <PasswordModal onChange={handleChange} />
      </ClientOnly>
    );
  };

  return conditionalLayoutBody();
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
