import styled, { createGlobalStyle } from 'styled-components';
import { getTypography, color, screenMin } from '@helpers/styles';
import { VikingHero } from '@illustrations';

const GlobalStyle = createGlobalStyle`
  :root {
    --illustration-dark: ${color.bg.primary};
    --illustration-light: ${color.bg.light};
    --illustration-accent-1: ${color.bg.secondary}
  }

  body {
    margin: 0;
    padding: 0;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  overflow-y: auto;
`;

const PasswordModalContent = styled.div`
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 4px;

  ${screenMin('lg')} {
    padding: 8vh 5vw;
  }
`;

const PasswordModalHeadline = styled.h1`
  margin-top: 0;
  text-align: center;

  ${getTypography('heading-4')}
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 4px;

  ${getTypography('heading-4')}
`;

const ModalPicture = styled(VikingHero)`
  flex: 1;
  min-height: 200px;
  max-height: 50vh;
`;

export { GlobalStyle, ModalContainer, PasswordModalContent, PasswordModalHeadline, Input, ModalPicture };
