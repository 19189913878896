import React from 'react';

export const VikingPennant = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 434 448" {...props}>
    <path
      fill="var(--illustration-dark)"
      d="M121.952 371.411c1.641 0 30.072 21.328 29.524 19.141-.548-2.188-5.467-29.532-5.467-29.532l-24.057 10.391z"
    />
    <path
      fill="var(--illustration-light)"
      d="M151.396 391.468c-1.036 0-3.821-1.722-16.744-10.764-5.23-3.659-11.723-8.199-12.766-8.542h-3.568l28.261-12.205.168.931c.05.272 4.924 27.344 5.457 29.483.093.373-.032.731-.33.936a.786.786 0 01-.478.161zm-27.663-20.007c1.839 1.096 5.282 3.469 11.78 8.014 5.331 3.729 12.357 8.644 14.985 10.083-1.014-5.053-4.269-23.085-5.06-27.472l-21.705 9.375z"
    />
    <path fill="var(--illustration-dark)" d="M171.705 347.896l25.699 15.312-6.56-27.89-19.139 12.578z" />
    <path
      fill="var(--illustration-light)"
      d="M198.541 364.759l-28.249-16.833 21.035-13.827 7.214 30.66zm-25.419-16.895l23.147 13.794-5.91-25.124-17.237 11.33z"
    />
    <path fill="var(--illustration-dark)" d="M214.355 319.456l23.512 14.768-6.561-32.267-16.951 17.499z" />
    <path
      fill="var(--illustration-light)"
      d="M238.95 335.791l-25.779-16.19 18.586-19.188 7.193 35.378zm-23.412-16.478l21.243 13.342-5.927-29.154-15.316 15.812z"
    />
    <path fill="var(--illustration-dark)" d="M244.425 289.925l11.483-18.595 16.403 20.236-27.886-1.641z" />
    <path
      fill="var(--illustration-light)"
      d="M273.966 292.414l-30.841-1.814 12.698-20.564 18.143 22.378zm-28.241-3.165l24.93 1.466-14.664-18.092-10.266 16.626z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M266.297 256.564l11.482-22.424s18.043 24.611 16.404 24.611c-1.639 0-27.886-2.187-27.886-2.187z"
    />
    <path
      fill="var(--illustration-light)"
      d="M294.183 259.502c-1.651 0-26.875-2.1-27.948-2.19l-1.116-.092 12.548-24.504.718.981c.043.058 4.331 5.909 8.487 11.835 8.66 12.343 8.36 12.936 8.07 13.51a.842.842 0 01-.759.46zm-26.707-3.594c8.267.688 21.941 1.802 25.762 2.045-1.166-2.227-5.748-9.26-15.346-22.386l-10.416 20.341z"
    />
    <path fill="var(--illustration-dark)" d="M83.677 380.709l23.51-6.014-.545 27.344-22.965-21.33z" />
    <path
      fill="var(--illustration-light)"
      d="M107.357 403.728l-25.201-23.405 25.801-6.602-.6 30.007zm-22.161-22.631l20.727 19.253.493-24.682-21.22 5.429z"
    />
    <path fill="var(--illustration-dark)" d="M54.698 381.802l17.497.548-7.654 21.876-9.843-22.424z" />
    <path
      fill="var(--illustration-light)"
      d="M64.618 406.273l-11.086-25.257 19.709.616-8.623 24.641zm-8.754-23.683l8.597 19.589 6.688-19.111-15.285-.478z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M284.888 217.187l7.654-23.518s32.26 14.22 29.525 14.22c-2.732 0-37.179 9.298-37.179 9.298z"
    />
    <path
      fill="var(--illustration-light)"
      d="M283.745 218.273l8.341-25.625.761.336c.075.032 7.564 3.336 14.933 6.717 15.44 7.085 15.357 7.485 15.187 8.286-.062.303-.337.653-.898.653-1.932 0-23.177 5.544-36.986 9.273l-1.338.36zm9.255-23.58l-6.969 21.408c6.166-1.657 27.681-7.408 34.289-8.702-4.324-2.373-18.017-8.594-27.32-12.706z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M295.276 178.907l2.187-15.86s20.229 12.033 18.59 12.033c-1.641-.002-20.777 3.827-20.777 3.827z"
    />
    <path
      fill="var(--illustration-light)"
      d="M294.388 179.848l2.487-18.027.973.578c.048.027 4.781 2.845 9.43 5.719 9.871 6.101 9.784 6.419 9.593 7.117a.812.812 0 01-.815.593c-1.149 0-12.966 2.28-20.63 3.814l-1.038.206zm3.663-15.577l-1.889 13.692c3.583-.713 14.109-2.79 18.285-3.441-2.868-2.047-10.762-6.885-16.396-10.251z"
    />
    <path fill="var(--illustration-dark)" d="M300.198 137.342v15.86l17.497-6.017-17.497-9.843z" />
    <path
      fill="var(--illustration-light)"
      d="M299.447 154.253v-18.197l20.075 11.294-20.075 6.903zm1.502-15.627v13.524l14.92-5.13-14.92-8.394z"
    />
    <path fill="var(--illustration-dark)" d="M19.158 82.106L2.755 61.04l17.497-44.563L37.2 59.947 19.158 82.106z" />
    <path
      fill="var(--illustration-light)"
      d="M19.146 83.31L1.899 61.166l18.355-46.747L38.06 60.085 19.146 83.309zM3.61 60.918L19.17 80.9l17.173-21.088-16.094-41.274L3.61 60.918z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M42.305 126.403v261.768c.548.003 1.093.01 1.641.01 143.841 0 260.446-116.631 260.446-260.505 0-.425-.005-.85-.008-1.276H42.304v.003z"
    />
    <path
      fill="var(--illustration-light)"
      d="M43.944 388.935c-.363 0-.726-.003-1.089-.005l-1.303-.01V125.652H305.13l.008 1.261c.002.256.005.511.005.766 0 35.268-6.906 69.484-20.528 101.693-13.156 31.111-31.989 59.051-55.976 83.044-23.988 23.993-51.921 42.83-83.025 55.989-32.202 13.622-66.408 20.53-101.67 20.53zm-.888-1.507c.295.003.593.005.89.005 35.06 0 69.068-6.867 101.085-20.412 30.923-13.083 58.697-31.811 82.547-55.666 23.85-23.856 42.575-51.635 55.654-82.566 13.541-32.024 20.407-66.04 20.407-101.107 0-.175 0-.351-.003-.526H43.056v260.272z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M22.025 447.247h-5.371c-7.777 0-14.082-6.307-14.082-14.085V87.792c0-7.779 6.305-14.085 14.082-14.085h5.371c7.777 0 14.082 6.306 14.082 14.085v345.37c0 7.781-6.302 14.085-14.082 14.085z"
    />
    <path
      fill="var(--illustration-light)"
      d="M22.025 448h-5.372c-8.179 0-14.832-6.655-14.832-14.836V87.794c0-8.181 6.653-14.836 14.832-14.836h5.372c8.18 0 14.833 6.655 14.833 14.836v345.37c0 8.179-6.653 14.836-14.833 14.836zM16.653 74.46c-7.35 0-13.33 5.981-13.33 13.334v345.37c0 7.353 5.98 13.334 13.33 13.334h5.372c7.352 0 13.331-5.981 13.331-13.334V87.794c0-7.353-5.98-13.334-13.33-13.334h-5.373z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M317.601 115.108v5.188c0 1.159-.938 2.1-2.099 2.1H33.318v-9.385h282.184a2.093 2.093 0 012.099 2.097z"
    />
    <path
      fill="var(--illustration-light)"
      d="M315.504 123.144H32.567v-10.886h282.935a2.85 2.85 0 012.847 2.848v5.188a2.849 2.849 0 01-2.845 2.85zm-281.436-1.501h281.434c.743 0 1.346-.606 1.346-1.349v-5.188a1.35 1.35 0 00-1.346-1.347H34.068v7.884z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M52.511 136.598v237.721c.495.002.993.01 1.489.01 130.624 0 236.518-105.918 236.518-236.572 0-.388-.005-.773-.008-1.159H52.511z"
    />
    <path
      fill="var(--illustration-light)"
      d="M54 375.08c-.325 0-.65-.003-.976-.005l-1.266-.008v-239.22h239.498l.008 1.194c.002.238.005.476.005.716 0 32.039-6.273 63.117-18.646 92.376-11.95 28.26-29.059 53.639-50.848 75.435-21.79 21.796-47.164 38.906-75.418 50.859C117.105 368.803 86.032 375.08 54 375.08zm-.738-1.507c.245.002.493.002.738.002 31.83 0 62.705-6.234 91.772-18.532 28.076-11.877 53.289-28.881 74.943-50.539 21.653-21.658 38.652-46.877 50.527-74.959 12.293-29.074 18.528-59.957 18.528-91.793 0-.135 0-.273-.003-.408H53.262v236.229z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M82.77 128.525a5.042 5.042 0 01-4.617-3.018l-5.104-11.668a5.035 5.035 0 119.225-4.037l5.104 11.668a5.037 5.037 0 01-4.609 7.055z"
    />
    <path
      fill="var(--illustration-light)"
      d="M82.77 129.276a5.787 5.787 0 01-5.303-3.469l-5.104-11.667a5.796 5.796 0 012.98-7.621 5.792 5.792 0 017.619 2.981l5.104 11.667a5.795 5.795 0 01-5.297 8.109zm-5.113-21.741a4.288 4.288 0 00-3.918 6.004l5.104 11.667a4.288 4.288 0 005.643 2.208 4.289 4.289 0 002.206-5.644l-5.104-11.667a4.29 4.29 0 00-3.93-2.568z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M168.976 128.525a5.041 5.041 0 01-4.616-3.018l-5.104-11.668a5.037 5.037 0 012.594-6.632 5.036 5.036 0 016.631 2.595l5.104 11.668a5.036 5.036 0 01-4.609 7.055z"
    />
    <path
      fill="var(--illustration-light)"
      d="M168.976 129.276a5.787 5.787 0 01-5.302-3.469l-5.104-11.667a5.795 5.795 0 012.98-7.621 5.792 5.792 0 017.619 2.981l5.104 11.667a5.794 5.794 0 01-5.297 8.109zm-5.112-21.741a4.288 4.288 0 00-3.918 6.004l5.104 11.667a4.289 4.289 0 005.643 2.208 4.29 4.29 0 002.207-5.644l-5.105-11.667a4.29 4.29 0 00-3.931-2.568z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M255.184 128.525a5.041 5.041 0 01-4.616-3.018l-5.104-11.668a5.035 5.035 0 119.225-4.037l5.104 11.668a5.037 5.037 0 01-4.609 7.055z"
    />
    <path
      fill="var(--illustration-light)"
      d="M255.184 129.276a5.787 5.787 0 01-5.302-3.469l-5.104-11.667a5.794 5.794 0 012.983-7.621 5.791 5.791 0 017.618 2.981l5.105 11.667a5.795 5.795 0 01-5.3 8.109zm-5.111-21.741a4.288 4.288 0 00-3.919 6.004l5.105 11.667a4.287 4.287 0 005.642 2.208 4.29 4.29 0 002.207-5.644l-5.105-11.667a4.287 4.287 0 00-3.93-2.568z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M37.932 164.819c-6.466 2.34-12.773 3.413-18.87 3.183-7.717-.29-14.128-2.608-18.311-4.617v16.054a83.628 83.628 0 0016.383 2.308 86.865 86.865 0 0020.797-1.637v-15.291z"
    />
    <path
      fill="var(--illustration-light)"
      d="M20.785 182.573c-1.224 0-2.462-.025-3.683-.078a84.745 84.745 0 01-16.529-2.327L0 180.027v-17.836l1.076.518c3.983 1.915 10.341 4.257 18.012 4.545 5.96.228 12.213-.831 18.589-3.139l1.005-.365v16.978l-.603.12a88.017 88.017 0 01-17.294 1.725zM1.5 178.846a83.29 83.29 0 0015.663 2.15 86.483 86.483 0 0020.017-1.502V165.88c-6.21 2.127-12.31 3.091-18.15 2.873-7.269-.273-13.394-2.328-17.53-4.192v14.285z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M37.932 248.858c-6.466 2.34-12.773 3.414-18.87 3.184-7.715-.291-14.125-2.608-18.311-4.618v16.055a83.707 83.707 0 0016.383 2.307 86.79 86.79 0 0020.797-1.637v-15.291z"
    />
    <path
      fill="var(--illustration-light)"
      d="M20.785 266.612c-1.224 0-2.462-.025-3.683-.078a84.669 84.669 0 01-16.529-2.327l-.573-.14v-17.839l1.076.518c3.983 1.914 10.341 4.257 18.012 4.545 5.963.225 12.213-.834 18.589-3.139l1.005-.365v16.978l-.603.12a87.868 87.868 0 01-17.294 1.727zM1.5 262.885a83.29 83.29 0 0015.663 2.15 86.482 86.482 0 0020.017-1.501v-13.615c-6.21 2.127-12.308 3.091-18.15 2.873-7.269-.273-13.394-2.327-17.53-4.192v14.285z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M37.932 332.898c-6.466 2.34-12.773 3.413-18.87 3.183-7.715-.29-14.125-2.608-18.311-4.617v16.054a83.704 83.704 0 0016.383 2.308 86.865 86.865 0 0020.797-1.637v-15.291z"
    />
    <path
      fill="var(--illustration-light)"
      d="M20.785 350.652c-1.229 0-2.467-.025-3.683-.078a84.745 84.745 0 01-16.529-2.327L0 348.106v-17.839l1.076.518c3.983 1.915 10.341 4.257 18.012 4.545 5.963.223 12.213-.831 18.589-3.138l1.005-.366v16.979l-.603.12a87.868 87.868 0 01-17.294 1.727zM1.5 346.925a83.29 83.29 0 0015.663 2.15 86.483 86.483 0 0020.017-1.502v-13.614c-6.21 2.127-12.308 3.093-18.15 2.873-7.269-.273-13.394-2.328-17.53-4.192v14.285z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M50.35 174.829a5.039 5.039 0 01-4.644 2.976l-12.731-.123a5.037 5.037 0 01.095-10.073l12.73.122a5.037 5.037 0 014.987 5.086 4.994 4.994 0 01-.438 2.012z"
    />
    <path
      fill="var(--illustration-light)"
      d="M45.756 178.556H45.7l-12.731-.123a5.794 5.794 0 01-5.73-5.841 5.793 5.793 0 015.785-5.731h.055l12.73.122a5.794 5.794 0 015.73 5.842 5.79 5.79 0 01-5.785 5.731zM33.023 168.36a4.29 4.29 0 00-4.284 4.244 4.293 4.293 0 004.243 4.328l12.731.122h.043a4.29 4.29 0 003.91-2.533 4.292 4.292 0 00-3.87-6.038l-12.731-.123h-.042z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M50.35 257.958a5.039 5.039 0 01-4.644 2.976l-12.73-.123a5.036 5.036 0 01.095-10.073l12.73.122a5.037 5.037 0 014.987 5.086 5.005 5.005 0 01-.438 2.012z"
    />
    <path
      fill="var(--illustration-light)"
      d="M45.756 261.684h-.058l-12.73-.122c-3.19-.03-5.76-2.653-5.73-5.844a5.793 5.793 0 015.785-5.731h.055l12.73.122a5.795 5.795 0 015.225 8.157 5.787 5.787 0 01-5.277 3.418zm-12.733-10.196a4.29 4.29 0 00-4.284 4.245 4.292 4.292 0 004.244 4.327l12.73.123a4.271 4.271 0 004.324-4.242 4.29 4.29 0 00-4.243-4.327l-12.731-.123c-.013-.003-.028-.003-.04-.003z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M50.35 343.637a5.038 5.038 0 01-4.644 2.975l-12.73-.122a5.037 5.037 0 01.095-10.074l12.73.123a5.037 5.037 0 014.987 5.085 4.991 4.991 0 01-.438 2.013z"
    />
    <path
      fill="var(--illustration-light)"
      d="M45.756 347.363h-.058l-12.73-.123a5.793 5.793 0 01-5.73-5.843 5.793 5.793 0 015.785-5.731h.055l12.73.122a5.743 5.743 0 014.074 1.734 5.757 5.757 0 011.654 4.11 5.728 5.728 0 01-.506 2.312 5.775 5.775 0 01-5.274 3.419zM33.023 337.17a4.29 4.29 0 00-4.284 4.244 4.29 4.29 0 004.244 4.327l12.73.123a4.272 4.272 0 004.324-4.242 4.262 4.262 0 00-1.226-3.041 4.249 4.249 0 00-3.017-1.284l-12.731-.122c-.013-.005-.028-.005-.04-.005z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M120.309 187.826l-31.552 31.558 73.174 72.522 69.14-72.937-29.532-31.143h-81.23z"
    />
    <path
      fill="var(--illustration-light)"
      d="M161.949 292.98l-74.258-73.593L120 187.075h81.863l30.243 31.894-.49.515-69.666 73.496zm-72.128-73.598l72.093 71.448 68.122-71.864-28.819-30.389h-80.598l-30.798 30.805z"
    />
    <path fill="var(--illustration-dark)" d="M120.308 187.825l32.225 36.932-28.867 29.544h73.174" />
    <path
      fill="var(--illustration-light)"
      d="M196.84 255.052h-74.958l29.63-30.325-31.769-36.408 1.131-.986 32.679 37.452-28.106 28.766h71.393v1.501z"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M294.763 249.307h28.968c29.708 0 56.596-12.01 76.066-31.428 19.466-19.425 31.506-46.246 31.506-75.88 0-29.635-12.04-56.456-31.507-75.878-19.469-19.418-46.357-31.428-76.065-31.428H167V33h156.731C384.08 33 433 81.802 433 141.999c0 60.199-48.92 108.998-109.269 109.001h-28.968v-1.693z"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M257.189 232.33h65.808c25.178 0 48-10.129 64.541-26.487 16.537-16.359 26.776-38.937 26.776-63.843 0-24.906-10.239-47.481-26.776-63.843C371 61.8 348.175 51.67 322.997 51.67H92V50h230.997C374.282 50.003 416 91.27 416 142c-.003 50.73-41.718 91.997-93.003 92h-65.808v-1.67z"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M283.191 218.295h38.551c20.837 0 39.724-8.554 53.41-22.372 13.687-13.818 22.16-32.886 22.16-53.923s-8.473-40.105-22.16-53.923c-13.686-13.819-32.573-22.373-53.41-22.373H51V64h270.742c42.602.003 77.255 34.989 77.258 78-.003 43.011-34.656 77.997-77.258 78h-38.551v-1.705z"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M317.5 200.354h5.995c16.494 0 31.442-6.542 42.277-17.112 10.832-10.57 17.54-25.154 17.54-41.242 0-16.091-6.705-30.672-17.54-41.242-10.835-10.567-25.786-17.112-42.277-17.112H44V82h279.495c33.917.003 61.505 26.913 61.505 60-.003 33.087-27.588 60-61.505 60H317.5v-1.646z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M279.348 369.245a2.7 2.7 0 002.697-2.701 2.7 2.7 0 00-2.697-2.701 2.7 2.7 0 00-2.698 2.701 2.7 2.7 0 002.698 2.701zM317.242 366.544a2.7 2.7 0 11-5.397 0 2.699 2.699 0 115.397 0zM279.348 404.189a2.7 2.7 0 002.697-2.701 2.7 2.7 0 00-2.697-2.701 2.7 2.7 0 00-2.698 2.701 2.7 2.7 0 002.698 2.701zM317.242 401.488a2.699 2.699 0 11-5.397 0 2.699 2.699 0 115.397 0z"
    />
    <path
      fill="var(--illustration-accent-1)"
      d="M288.932 49.028c8.581-8.593 8.581-22.525 0-31.118-8.58-8.594-22.491-8.594-31.072 0-8.58 8.593-8.58 22.525 0 31.118 8.581 8.593 22.492 8.593 31.072 0z"
    />
  </svg>
);
