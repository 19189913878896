import React from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash.uniq';
import { Flag, FlagsWrapper } from './Flags.styled';

// Flags are named by 2 letter country ISO code
export const Flags = ({ flags, className, align }) => {
  const urlCdn = process.env.GATSBY_URL_CDN_FLAGS;
  const filteredFlags = uniq(flags);

  if (align !== 'right' && align !== 'left') throw new Error('Align can only receive "left" or "right" values.');

  if (filteredFlags.find((el) => el.length > 2))
    throw new Error('The maximum length for a country code is 2 characters.');

  return (
    <FlagsWrapper className={className} align={align}>
      {filteredFlags.map((countryCode) => (
        <Flag key={countryCode} src={`${urlCdn}/${countryCode}.svg`} />
      ))}
    </FlagsWrapper>
  );
};

Flags.propTypes = {
  flags: PropTypes.arrayOf(PropTypes.string).isRequired,
  align: PropTypes.string,
  className: PropTypes.string,
};

Flags.defaultProps = {
  align: 'left',
  className: null,
};
