const DEFAULT_LINK_ARRAY = [
  {
    to: '/software-development',
    title: 'Software Development Services',
    label: 'Software Development',
    external: false,
  },
  {
    to: '/advisory',
    title: 'Advisory Services',
    label: 'Advisory',
    external: false,
  },
  {
    to: '/fund',
    title: 'Ragnarson Fund',
    label: 'Ragnarson Fund',
    external: false,
  },
  {
    to: '/manifesto',
    title: 'Manifesto',
    label: 'Manifesto',
    external: false,
  },
  {
    to: '/case-studies',
    title: 'Case studies',
    label: 'Case studies',
    external: false,
  },
  {
    to: 'https://jobs.ragnarson.com',
    title: 'Career at Ragnarson',
    label: 'Career',
    external: true,
  },
  {
    to: 'https://blog.ragnarson.com',
    title: 'Ragnarson Blog',
    label: 'Blog',
    external: false,
  },
  {
    to: '/founding-impact',
    title: 'Founding Impact',
    label: 'Founding Impact',
    external: false,
  },
  {
    to: '/team',
    title: 'Team',
    label: 'Team',
    external: false,
  },
];

const DEFAULT_LINK_ARRAY_SOCIAL = [
  {
    to: 'https://www.facebook.com/ragnarson',
    vectorName: 'logo-facebook',
    title: 'Go to Ragnarson Facebook profile.',
  },
  {
    to: 'https://www.linkedin.com/company/ragnarson/',
    vectorName: 'logo-linkedin',
    title: 'Go to Ragnarson Linkedin profile.',
  },
  {
    to: 'https://github.com/Ragnarson',
    vectorName: 'logo-github',
    title: 'Go to Ragnarson Github profile.',
  },
];

const DEFAULT_COMPANY_DETAILS = {
  name: 'Ragnarson Sp. z o.o. Sp. K.',
  officeArray: [
    {
      country: 'Germany',
      city: 'Berlin',
      address: ['Unicorn Workspaces', 'Voltastrasse 5', '13355 Berlin', 'Gebäude 10, Aufgang 2', '4th floor'],
    },
    {
      country: 'Poland',
      city: 'Łódź',
      address: ['ul. Targowa 35', '90-043 Łódź'],
    },
  ],
  idArray: [
    ['krs', '0000543467'],
    ['nip', '7252082509'],
    ['regon', '360788667'],
  ],
};

const DEFAULT_COPYRIGHT = {
  privacyPolicy: {
    to: 'https://www.iubenda.com/privacy-policy/50541107/full-legal',
    label: 'Privacy Policy',
    title: 'Go to Privacy Policy',
  },
};

export { DEFAULT_LINK_ARRAY, DEFAULT_LINK_ARRAY_SOCIAL, DEFAULT_COMPANY_DETAILS, DEFAULT_COPYRIGHT };
