import React from 'react';
import PropTypes from 'prop-types';
import { RowBlockContainer, RowBlockCell, RowBlockTitle, RowBlockContent } from './RowBlock.styled';

export const RowBlock = ({ className, content }) => {
  return (
    <RowBlockContainer className={className}>
      {content.map((block, index) => (
        <RowBlockCell key={index}>
          <RowBlockTitle>{block.title}</RowBlockTitle>
          <RowBlockContent>{block.content}</RowBlockContent>
        </RowBlockCell>
      ))}
    </RowBlockContainer>
  );
};

RowBlock.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired, content: PropTypes.string.isRequired })
  ).isRequired,
};

RowBlock.defaultProps = {
  className: '',
};
