import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const ServicePodcast = ({ config, className }) => (
  <SVGInterface width={32} height={32} config={config} className={className}>
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#C95BF2' }} />
        <stop offset="100%" style={{ stopColor: '#851CC1' }} />
      </linearGradient>
    </defs>
    <rect fill="url(#grad1)" height="32" width="32" rx="10" ry="10" />
    <path
      fill="var(--illustration-light)"
      d="M 16.5 3 C 10.159 3 5 8.159 5 14.5 C 5 19.149 7.7788125 23.153844 11.757812 24.964844 C 11.644813 24.120844 11.555531 23.283219 11.519531 22.574219 C 8.8125313 20.898219 7 17.911 7 14.5 C 7 9.262 11.262 5 16.5 5 C 21.738 5 26 9.262 26 14.5 C 26 17.911 24.187469 20.898219 21.480469 22.574219 C 21.444469 23.282219 21.355187 24.120844 21.242188 24.964844 C 25.221187 23.153844 28 19.149 28 14.5 C 28 8.159 22.841 3 16.5 3 z M 16.5 7 C 12.364 7 9 10.364 9 14.5 C 9 16.854 10.092922 18.956031 11.794922 20.332031 C 12.020922 19.721031 12.4025 19.154641 12.9375 18.681641 C 11.7545 17.671641 11 16.173 11 14.5 C 11 11.468 13.468 9 16.5 9 C 19.532 9 22 11.468 22 14.5 C 22 16.173 21.2455 17.671641 20.0625 18.681641 C 20.5975 19.153641 20.980078 19.720031 21.205078 20.332031 C 22.907078 18.956031 24 16.854 24 14.5 C 24 10.364 20.636 7 16.5 7 z M 16.5 11 C 14.57 11 13 12.57 13 14.5 C 13 16.43 14.57 18 16.5 18 C 18.43 18 20 16.43 20 14.5 C 20 12.57 18.43 11 16.5 11 z M 16.5 13 C 17.327 13 18 13.673 18 14.5 C 18 15.327 17.327 16 16.5 16 C 15.673 16 15 15.327 15 14.5 C 15 13.673 15.673 13 16.5 13 z M 16.5 19 C 13.341 19 13 21.07575 13 21.96875 C 13 23.61275 13.537078 26.919828 13.830078 28.173828 C 13.959078 28.723828 14.478 30 16.5 30 C 18.522 30 19.040922 28.723828 19.169922 28.173828 C 19.462922 26.920828 20 23.61275 20 21.96875 C 20 21.07575 19.659 19 16.5 19 z M 16.5 21 C 18 21 18 21.55975 18 21.96875 C 18 23.30375 17.529656 26.399797 17.222656 27.716797 C 17.197656 27.821797 17.156 28 16.5 28 C 15.844 28 15.802344 27.82275 15.777344 27.71875 C 15.471344 26.40475 15 23.30475 15 21.96875 C 15 21.55975 15 21 16.5 21 z"
    />
  </SVGInterface>
);

ServicePodcast.propTypes = DEFAULT_TYPES;
ServicePodcast.defaultProps = DEFAULT_PROPS;
