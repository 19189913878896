import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';
import LinkComponent from './LinkExternal.styled';

export const LinkExternal = ({ className, target, to, title, children, ...props }) => (
  <LinkComponent className={className} href={to} aria-label={title} title={title} target={target} {...props}>
    {children}
  </LinkComponent>
);

LinkExternal.propTypes = {
  /* eslint-disable react/require-default-props  */
  className: PropTypes.string,
  title: PropTypes.string,
  /* eslint-enable react/require-default-props  */
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
};

LinkExternal.defaultProps = {
  target: null,
};
