import React from 'react';
import PropTypes from 'prop-types';
import sprite from '@images/backgrounds.svg';
import { StringsContainer, SvgWrapper } from './Strings.styled';

export const Strings = ({ className, name, top, left, height, mobile }) => (
  <StringsContainer className={className} top={top} left={left} height={height} mobile={mobile}>
    <SvgWrapper>
      <use xlinkHref={`${sprite}#${name}`} />
    </SvgWrapper>
  </StringsContainer>
);

Strings.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  name: PropTypes.string,
  /* eslint-enable react/require-default-props */
  top: PropTypes.number,
  left: PropTypes.number,
  height: PropTypes.number,
  mobile: PropTypes.bool,
};

Strings.defaultProps = {
  top: 0,
  left: 0,
  height: 0,
  mobile: false,
};
