/**
 *  TODO: Refactor the TextEffect to a Highlight component without any margins and keep effect under the exterior text too.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { spaceDt, spaceMb, color, screenMin } from '@helpers/styles';

const TextEffectBase = styled.span`
  ${({ effect }) =>
    effect.startsWith('text-') &&
    css`
      color: ${color.text[effect.substring(5)]};
    `}
  ${({ effect }) =>
    effect === 'highlight' &&
    css`
      position: relative;
      z-index: 1;
      margin-right: ${spaceMb(1)};

      ${screenMin('lg')} {
        margin-right: ${spaceDt(2)};
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: ${color.bg.secondary};
        left: -2%;
        right: -3.53%;
        top: 18.31%;
        bottom: 3.48%;
        z-index: -1;
        transform: rotate(-7deg);
      }
    `}
`;

export const TextEffect = ({ effect, children }) => <TextEffectBase effect={effect}>{children}</TextEffectBase>;

TextEffect.propTypes = {
  effect: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
