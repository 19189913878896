import styled from 'styled-components';
import { color, getTypography } from '@helpers/styles';

const LinkComponent = styled.a`
  color: ${color.text.primary};

  &:hover {
    color: ${color.text.secondary};
  }

  ${getTypography('body-2')}
`;

export default LinkComponent;
