import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const Verivox = ({ config, className }) => (
  <SVGInterface width={156} height={36} config={config} className={className}>
    <path fill="var(--illustration-dark)" d="M78.6 7.4h5.2v22.7h-5.2z" />
    <path
      fill="var(--illustration-dark)"
      d="M31.5 7.4l-7.4 19.9-.1.2c-.6 1.6-2.1 2.8-3.8 3h-1.4c-1.7-.2-3.1-1.4-3.7-2.9l-.1-.3L7.6 7.4h6.1L19.5 23l5.8-15.6.6-1.5C26.8 3.5 23.5 0 13.4 0S-.4 4.3 0 9.1c.4 4.9 6 16 8.9 20.4 2.8 4.3 6.6 6.4 10.5 6.4 3.9 0 8-2 10.4-8.1 1.8-4.6 7.8-20.4 7.8-20.4h-6.1z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M59.5 19.2c0 .5 0 .9-.1 1.4H42.7c.6 3.7 3.2 5.7 6.5 5.7 2.5 0 4.3-.9 6.1-2.7l3 2.7c-2.1 2.6-5.1 4.2-9.3 4.2-6.6 0-11.6-4.8-11.6-11.8 0-6.5 4.6-11.8 11.1-11.8 7.3 0 11 5.7 11 12.3zm-5.2-2c-.3-3.3-2.3-6-5.8-6-3.2 0-5.4 2.4-5.9 6h11.7zm93.3 1.3l8-11h-5.5l-5.2 7.5-5.2-7.5H134l8 11.2-8.3 11.5h5.5l5.5-7.9 5.6 7.9h5.7l-8.4-11.7z"
    />
    <path
      fill="var(--illustration-dark)"
      d="M133.9 18.8c0 6.5-5.3 11.8-11.8 11.8-6.5 0-11.8-5.3-11.8-11.8 0-6.5 5.3-11.8 11.8-11.8 6.5-.1 11.8 5.2 11.8 11.8zm-4.6 0c0-4-3.2-7.2-7.2-7.2s-7.2 3.2-7.2 7.2 3.2 7.2 7.2 7.2 7.2-3.3 7.2-7.2zM104.9 7.4l-6.1 16.5-6.1-16.5h-5.6l9.3 22.7h4.7l9.3-22.7zM63 17.8v12.3h5.2V17.2c.2-1.4 1-4.2 5.1-4.5h.8V7.4h-.5c-7.1.2-10.5 5.2-10.6 10v.4z"
    />
  </SVGInterface>
);

Verivox.defaultProps = DEFAULT_PROPS;
Verivox.propTypes = DEFAULT_TYPES;
