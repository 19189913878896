import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { spaceMb, spaceDt, screenMax } from '@helpers/styles';

const Flag = styled.img`
  width: ${toVW(30, 'desktop')};
  height: ${toVW(20, 'desktop')};
  margin: ${spaceDt(0.5)};

  ${screenMax('lg')} {
    width: ${toVW(18, 'mobile')};
    height: ${toVW(12, 'mobile')};
    margin: 0;

    &:not(:first-child) {
      margin-left: ${spaceMb(0.5)};
    }
  }
`;

const FlagsWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
  flex-wrap: wrap-reverse;

  ${screenMax('lg')} {
    bottom: ${spaceMb(0.5)};
    right: ${spaceMb(0.5)};
  }
`;

export { Flag, FlagsWrapper };
