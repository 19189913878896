import styled, { css } from 'styled-components';
import { screenMin, spaceMb, spaceDt } from '@helpers/styles';

export const TagGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          padding: 0;
          margin: ${spaceMb(1)} 0;

          ${screenMin('lg')} {
            margin: ${spaceDt(2)} ${spaceDt(-1)};
          }
        `;
      default:
        return css`
          padding: 0 ${spaceMb(1.5)};
          margin: ${spaceMb(1)} 0;

          ${screenMin('lg')} {
            padding: 0 ${spaceDt(21)};
            margin: ${spaceDt(2)} 0;
          }
        `;
    }
  }}
`;
