import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';
import {
  SectionContainer,
  SectionVector,
  SectionContentWrapper,
  SectionName,
  SectionTitle,
  SectionContent,
  SectionContentAside,
  SectionWrapper,
} from './SectionSplit.styled';

export const SectionSplit = ({ className, compact, id, vectorName, name, title, content, contentAside }) => {
  const hasAside = Boolean(contentAside);
  const hasContent = Boolean(content);
  /**
   * At the moment, this section is used in two places, Case Study page and Software Development page
   * Both pages have slightly different design inside SectionSplit. For now it is required to use "compact"
   * indicator to match Software Development page design requirements.
   * TODO: Find out if there is a better solution than passing props to styled components
   */
  return (
    <SectionContainer className={className} id={id}>
      <SectionWrapper>
        <SectionVector name={vectorName} />

        <SectionContentWrapper compact={compact} hasAside={hasAside}>
          <div>
            <SectionName compact={compact}>{name}</SectionName>
            <SectionTitle>{title}</SectionTitle>
          </div>

          {hasContent && <SectionContent hasAside={hasAside}>{content}</SectionContent>}
        </SectionContentWrapper>
      </SectionWrapper>
      {hasAside && <SectionContentAside>{contentAside}</SectionContentAside>}
    </SectionContainer>
  );
};

SectionSplit.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  compact: PropTypes.bool,
  id: PropTypes.string,
  vectorName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentAside: PropTypes.oneOfType([TYPE_COMPONENT, PropTypes.string]),
};

SectionSplit.defaultProps = {
  compact: false,
  id: null,
  content: null,
  contentAside: null,
};
