/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react');
const Layout = require('./src/features/Layout').default;
const StylesGlobal = require('./src/components/fragments/StylesGlobal').default;

// eslint-disable-next-line react/prop-types
exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      <StylesGlobal />
      <Layout {...props}>{element}</Layout>
    </>
  );
};
