import React from 'react';
import { SVGInterface } from '@fragments';
import { DEFAULT_PROPS, DEFAULT_TYPES } from '../_constants';

export const Melodie = ({ config, className }) => (
  <SVGInterface width={205} height={37} config={config} className={className}>
    <path
      fill="var(--illustration-dark)"
      d="M97.61 30.09v-6.48l-2.78 4.45c-.03.07-.09.11-.17.13a.88.88 0 01-.2.03c-.07 0-.14-.01-.22-.03a.25.25 0 01-.17-.13l-2.78-4.45v6.48h-3.4v-12.6h3.19c.11 0 .21.05.28.15l3.09 4.99 3.1-4.99c.08-.1.17-.15.27-.15h3.2V30.1h-3.41v-.01zm10.79 0v-12.6h10.38v3.01h-6.99v1.8h6.59v3.01h-6.59v1.8h6.99v2.99H108.4v-.01zm16.92 0v-12.6h3.61v9.49h6.84v3.12h-10.45v-.01zm23.83.39c-.96 0-1.86-.17-2.7-.52-.84-.35-1.57-.82-2.2-1.42-.63-.6-1.12-1.31-1.48-2.12a6.34 6.34 0 01-.54-2.6c0-.92.18-1.79.54-2.6.36-.81.86-1.52 1.48-2.12.63-.6 1.36-1.08 2.2-1.43.84-.35 1.74-.53 2.7-.53.95 0 1.85.18 2.69.53.84.35 1.58.83 2.21 1.43.63.6 1.12 1.31 1.48 2.12.36.81.55 1.68.55 2.6 0 .92-.18 1.79-.55 2.6-.36.81-.86 1.52-1.48 2.12-.63.6-1.36 1.07-2.21 1.42-.84.35-1.74.52-2.69.52zm0-10.12c-.49 0-.95.09-1.37.28-.42.19-.78.44-1.08.76-.3.31-.54.68-.71 1.1-.17.42-.26.86-.26 1.32 0 .45.09.89.26 1.32.17.42.41.79.71 1.1.3.31.66.57 1.08.76.42.19.88.28 1.37.28.49 0 .95-.09 1.37-.28.42-.19.78-.44 1.08-.76.3-.31.54-.68.71-1.1a3.49 3.49 0 000-2.64c-.17-.42-.41-.78-.71-1.1-.3-.31-.66-.57-1.08-.76-.42-.18-.88-.28-1.37-.28zm19.94 9.73h-5.82v-12.6h5.82c.91 0 1.76.17 2.55.5.79.33 1.49.78 2.08 1.35a6.32 6.32 0 011.4 2.01c.34.77.51 1.59.51 2.45 0 .87-.17 1.68-.51 2.45a6.32 6.32 0 01-1.4 2.01c-.59.57-1.29 1.02-2.08 1.35-.79.32-1.64.48-2.55.48zm-.4-9.53h-2v6.47h2a3.409 3.409 0 002.39-.94c.3-.29.54-.64.71-1.03a3.193 3.193 0 00-.71-3.55c-.3-.29-.66-.52-1.07-.69a3.24 3.24 0 00-1.32-.26zm14.34 9.53v-12.6h3.5V30.1h-3.5v-.01zm10.86 0v-12.6h10.38v3.01h-6.99v1.8h6.59v3.01h-6.59v1.8h6.99v2.99h-10.38v-.01z"
    />
    <path
      fill="var(--illustration-dark)"
      fillRule="evenodd"
      d="M40.26 11.79c.51-1.21 1.76-1.99 3.13-1.99 1.63 0 2.89 1.19 3.44 2.65.17.45.34.87.53 1.28 2.89 6.19 10.1 11.67 16.68 13.17 4.35.99 8.81.61 13.54-.19-7 2.55-14.25 4.13-21.37.85-4-1.84-7.38-4.88-10.51-7.9-1.22-1.08-2.86-1.25-4.29-.47-3.12 1.72-6.13 5.4-7.4 8.71-.27.74-.72 1.42-1.33 1.92-.62.51-1.37.82-2.21.82-1.7 0-2.96-1.22-3.53-2.71-.19-.5-.3-1.03-.33-1.56-.31-2.51-1.19-4.9-2.63-6.98-3.68-5.33-7.51-5.18-10.15 1.23-1.16 2.83-1.66 5.97-1.61 9.02.01.18.02.36.02.53 0 2.93-1.16 5.93-4.33 6.57-1.12.23-2.29.08-3.35-.34-1.18-.48-2.24-1.31-2.89-2.42-1.13-1.91-.77-4.06.45-5.83.7-1.03 1.56-1.84 2.5-2.65 1.18-1.01 2.35-1.9 3.25-3.19 2.54-3.63 4.25-8.07 4.32-12.54.03-1.68-.19-3.13.3-4.81.63-2.14 2.07-4.05 4.26-4.72C17.27.08 17.78 0 18.3 0s1.03.08 1.53.23c1.8.55 3.09 1.95 3.85 3.61.84 1.85.78 3.09.92 5.13.26 3.78.94 8.2 3.6 10.79 2.14 2.08 4.57 2.05 7.02.32 1.77-1.24 3.1-3.13 3.93-5.13.44-1.03.67-2.12 1.11-3.16z"
      clipRule="evenodd"
    />
  </SVGInterface>
);

Melodie.propTypes = DEFAULT_TYPES;
Melodie.defaultProps = DEFAULT_PROPS;
