import PropTypes from 'prop-types';

const TYPE_CONTENT_COMPANY_DETAILS_OFFICE = PropTypes.arrayOf(
  PropTypes.exact({
    country: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.arrayOf(PropTypes.string),
  })
);
const TYPE_CONTENT_COMPANY_DETAILS_IDS = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string));

const TYPE_CONTENT_COMPANY_NAME = PropTypes.string;

const TYPE_LINK = PropTypes.exact({
  to: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  external: PropTypes.bool,
});

const TYPE_CONTENT_LINK_ARRAY = PropTypes.arrayOf(TYPE_LINK);

const TYPE_CONTENT_LINK_ARRAY_SOCIAL = PropTypes.arrayOf(
  PropTypes.exact({
    to: PropTypes.string,
    vectorName: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  })
);

export {
  TYPE_CONTENT_COMPANY_DETAILS_OFFICE,
  TYPE_CONTENT_COMPANY_DETAILS_IDS,
  TYPE_CONTENT_COMPANY_NAME,
  TYPE_CONTENT_LINK_ARRAY,
  TYPE_CONTENT_LINK_ARRAY_SOCIAL,
  TYPE_LINK,
};
