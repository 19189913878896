import styled from 'styled-components';
import { spaceMb, spaceDt, getTypography, getFontSet, screenMin } from '@helpers/styles';

const FooterCompanyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${screenMin('md')} {
    width: auto;
    margin-left: auto;
  }
`;

const FooterCompanyOffice = styled.div`
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: ${spaceMb(5)};

    ${screenMin('lg')} {
      margin-right: ${spaceDt(5)};
    }
  }
`;

const FooterCompanyOfficeCity = styled.p`
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(2)};
    margin-right: ${spaceDt(5)};
  }
  ${getTypography('heading-4')};
`;

const FooterCompanyContent = styled.p`
  margin-bottom: 0;
  ${getFontSet('body-3', 'mobile')}

  ${screenMin('lg')} {
    ${getFontSet('body-2', 'desktop')}
  }
`;

const FooterCompanyDetails = styled.div`
  margin-top: ${spaceMb(1.5)};
  width: 100%;

  ${screenMin('lg')} {
    width: initial;
    flex-grow: 1;
    margin-top: ${spaceDt(9.5)};
  }
`;

export {
  FooterCompanyWrapper,
  FooterCompanyOffice,
  FooterCompanyOfficeCity,
  FooterCompanyContent,
  FooterCompanyDetails,
};
